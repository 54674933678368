import type { WorkflowPdfTemplate } from "../../types/index.js";
import { getValueFn } from "../thalamosMax0.1Workflow/pdfTemplate.js";
import type { DetentionDetailsFormData } from "./detentionDetails.js";
import type { InformationForHealthcareFormData } from "./informationForHealthcare.js";
import type { PersonDetailsFormData } from "./personDetails.js";
import type { TriageIncidentThalamosMin01FormData } from "./triageIncident.js";

export const min01PdfTemplate: WorkflowPdfTemplate<
  TriageIncidentThalamosMin01FormData,
  PersonDetailsFormData,
  DetentionDetailsFormData,
  InformationForHealthcareFormData
> = {
  sections: [
    {
      type: "text",
      textSections: [{ text: "Detention details", options: { bold: true } }],
      divider: true,
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueFn(data, "detentionDetails", "detentionTookPlace"),
      beforeLabelTextSections: [{ text: "Incident Location - Address" }],
    },
    {
      type: "text",
      textSections: [{ text: "Person details", options: { bold: true } }],
      divider: true,
    },
    {
      type: "textbox",
      valueFn: (data) =>
        data.incidentWorkItem.incidentWorkItem.patientForename
          .value satisfies string,
      beforeLabelTextSections: [{ text: "Person's First name(s)" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        data.incidentWorkItem.incidentWorkItem.patientSurname
          .value satisfies string,
      beforeLabelTextSections: [{ text: "Person's Surname" }],
    },
    {
      type: "textbox",
      valueFn: (data) => getValueFn(data, "personDetails", "patientAddress"),
      beforeLabelTextSections: [{ text: "Person's home address" }],
    },
    {
      type: "textbox",
      valueFn: (data) => getValueFn(data, "personDetails", "dateOfBirth"),
      beforeLabelTextSections: [{ text: "Person's date of birth" }],
    },
    {
      type: "textbox",
      valueFn: (data) => getValueFn(data, "personDetails", "gender"),
      beforeLabelTextSections: [{ text: "Gender" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueFn(data, "personDetails", "selfDeclaredEthnicity"),
      beforeLabelTextSections: [{ text: "Self declared ethnicity" }],
    },
    {
      type: "textbox",
      valueFn: (data) => getValueFn(data, "personDetails", "ethnicAppearance"),
      beforeLabelTextSections: [{ text: "Ethnic Appearance" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueFn(data, "personDetails", "relativeOrFriendForename"),
      beforeLabelTextSections: [{ text: "Relative or firend forename" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueFn(data, "personDetails", "relativeOrFriendSurname"),
      beforeLabelTextSections: [{ text: "Relative or firend surname" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueFn(data, "personDetails", "relativeOrFriendRelationship"),
      beforeLabelTextSections: [
        { text: "Relationship to person being detained" },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueFn(data, "personDetails", "relativeOrFriendAddress"),
      beforeLabelTextSections: [
        {
          text: "Home address of the relative or friend of the person being detained",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueFn(data, "personDetails", "relativeOrFriendTelephoneNumber"),
      beforeLabelTextSections: [
        {
          text: "Telephone number of relative or friend",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueFn(data, "personDetails", "relativeOrFriendInformed"),
      beforeLabelTextSections: [
        {
          text: "Has the relative or friend been informed of the detention?",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) => getValueFn(data, "personDetails", "reasonNotInformed"),
      beforeLabelTextSections: [
        {
          text: "Please explain why the relative or friend has not been informed",
        },
      ],
    },
    {
      type: "text",
      textSections: [{ text: "Health information", options: { bold: true } }],
      divider: true,
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueFn(
          data,
          "informationForHealthcare",
          "medicalAttentionProvided",
        ),
      beforeLabelTextSections: [
        {
          text: "Please describe the medical attention provided",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueFn(data, "informationForHealthcare", "hasPersonBeenSearched"),
      beforeLabelTextSections: [
        {
          text: "Has the person been searched?",
        },
      ],
    },
  ],
};
