import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import { dateTimeSchema, freeTextSchema } from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "custodyNumber",
    type: "textbox",
    ...new FieldBuilder().withNullableSchema(freeTextSchema).build(),
    label: "detentionDetails.custodyNumber",
  })
  .withFieldAlwaysVisible({
    fieldName: "dateTimeOfDetention",
    type: "date-time",
    timeLabel: "detentionDetails.timeOfDetention",
    dateLabel: "detentionDetails.dateOfDetention",
    schema: dateTimeSchema,
  })
  .withFieldAlwaysVisible({
    fieldName: "responsibleForDetention",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    label: "detentionDetails.responsibleForDetention",
  })
  .withFieldAlwaysVisible({
    fieldName: "detentionTookPlace",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    label: "detentionDetails.detentionTookPlace",
  })
  .withPage(["custodyNumber"])
  .withPage(["dateTimeOfDetention"])
  .withPage(["responsibleForDetention"])
  .withPage(["detentionTookPlace"])
  .build();

export type DetentionDetailsFormData = typeof formSpecification.formType;
