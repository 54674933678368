import React, { useMemo } from "react";

import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import {
  LocalizationProvider,
  DatePicker as MUIDataPicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TranslationKey } from "@vision/common";
import dayjs, { Dayjs } from "dayjs";
import { debug as debugFn } from "debug";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FormLabel } from "../FormLabel/FormLabel.js";

export type DatePickerProps = {
  fieldName: string;
  label?: TranslationKey;
  canBeUnknown?: boolean;
};

export type DatePickerOutput = {
  value: string | null;
  valueLabel?: string;
  isUnknown?: boolean;
};

export const DatePicker = ({
  fieldName,
  label,
  canBeUnknown,
}: DatePickerProps) => {
  const debug = debugFn("vision-frontend:DatePicker");

  const { t } = useTranslation();

  const [field, meta, helpers] = useField<DatePickerOutput>(fieldName);

  // We use this to store the last value that the user typed in the text field.
  // This is so that we can restore it if they turn on the "unknown" flag.
  // We pre-populate this with the incoming "initialValue".
  const [lastTypedValue, setLastTypedValue] = React.useState<string | null>(
    (field.value as unknown as string) === "" ? null : field.value.value,
  );

  // Clean up the field value to be a structured object
  const fieldValue = useMemo(
    () =>
      (field.value as unknown as string) === ""
        ? // Turn empty string into a structured object
          { value: null, isUnknown: false }
        : field.value.isUnknown === true
          ? // If "unknown", clear out the value
            { value: null, isUnknown: true }
          : field.value,
    [field.value],
  );

  // The interesting error to present to the user is the output
  // of the validation function on the "value" field, not the
  // entire object.
  const fieldError = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    () => (meta.error === undefined ? undefined : t(meta.error as any)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(meta.error)],
  );
  if (fieldError) {
    debug("fieldError", fieldError);
  }

  const handleDateChange = (value: Dayjs | null) => {
    // Parse the value
    const parsedValue =
      value && value.isValid() ? value.format("YYYY-MM-DD") : null;

    // Store away the thing they typed so we can restore it if they
    // turn on the unknown flag
    setLastTypedValue(parsedValue);

    const newValue = {
      value: parsedValue,
      ...(canBeUnknown ? { isUnknown: false } : {}),
    };

    debug("Setting value", newValue);
    helpers.setValue(newValue);
    helpers.setTouched(true);
  };

  const handleUnknownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
      ? {
          value: null,
          isUnknown: true,
        }
      : {
          value: lastTypedValue,
          isUnknown: false,
        };

    debug("handleUnknownChange, Setting value", newValue);

    helpers.setValue(newValue);
    helpers.setTouched(true);
  };

  return (
    <Stack data-testid={`formField-${fieldName}`}>
      {label && <FormLabel label={label} />}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MUIDataPicker<Dayjs>
          value={
            fieldValue.value || lastTypedValue
              ? dayjs(fieldValue.value || lastTypedValue, "YYYY-MM-DD")
              : null
          }
          onChange={handleDateChange}
          format="DD/MM/YYYY"
          disabled={fieldValue.isUnknown}
          slotProps={{
            textField: {
              error: meta.touched && Boolean(fieldError),
              // We always render the helper text so that the "unknown" checkbox
              // doesn't jump vertically when the error message appears.
              // Empty string would not be rendered, so we use a space.
              helperText: meta.touched ? (fieldError ?? " ") : " ",
              fullWidth: true,
              inputProps: {
                "data-testid": `formField-${fieldName}-textField`,
              },
            },
          }}
        />
      </LocalizationProvider>
      {canBeUnknown && (
        <FormControlLabel
          control={
            <Checkbox
              checked={fieldValue.isUnknown}
              onChange={handleUnknownChange}
              data-testid={`formField-${fieldName}-unknown`}
            />
          }
          label={t("unknown")}
        />
      )}
    </Stack>
  );
};
