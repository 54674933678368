import React, { useMemo } from "react";

import {
  createInitialValues,
  workflows,
  WorkflowVersions,
} from "@vision/common";
import { z } from "zod";
import {
  MultiPageForm,
  MultiPageFormSkeleton,
} from "../../components/FormBuilder/MultiPageForm/MultiPageForm.js";

type PreEndIncidentSchema<T extends WorkflowVersions> = z.infer<
  (typeof workflows)[T]["preEndIncident"]["formDefinition"]["sections"][0]["formSchema"]
>;

///

export function PreEndIncidentFormSkeleton() {
  return (
    <MultiPageFormSkeleton headerText={"preEndIncident.endIncidentTitle"} />
  );
}

///

export type PreEndIncidentFormProps<T extends WorkflowVersions> = {
  onSubmit: (value: PreEndIncidentSchema<T>) => void;
  onCancel: () => void;
  workflowVersion: T;
  initialValues?: PreEndIncidentSchema<T>;
};

///

export function PreEndIncidentForm<T extends WorkflowVersions>(
  props: PreEndIncidentFormProps<T>,
) {
  const workflow = workflows[props.workflowVersion];
  const formSchema =
    workflow.preEndIncident.formDefinition.sections[0].formSchema;
  const strictFieldSchemas =
    workflow.preEndIncident.formDefinition.sections[0].strictFieldSchemas;

  const initialValuesOrDefaults = useMemo(
    () =>
      props.initialValues ??
      (createInitialValues(strictFieldSchemas) as z.infer<typeof formSchema>),
    [props.initialValues, strictFieldSchemas],
  );

  return (
    <MultiPageForm<typeof formSchema>
      headerText={"preEndIncident.endIncidentTitle"}
      schema={formSchema}
      strictFieldSchemas={strictFieldSchemas}
      initialValues={initialValuesOrDefaults}
      pages={workflow.preEndIncident.formDefinition.sections[0].pages}
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
    />
  );
}
