import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  freeTextSchema,
  valueAndLabelSchema,
  yesNoSchema,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "incidentDescription",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    label: "informationForHealthcare.incidentDescription",
  })
  .withFieldAlwaysVisible({
    fieldName: "wasHealthcareProvided",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "informationForHealthcare.wasHealthcareProvided",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "medicalAttentionProvided",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.wasHealthcareProvided?.value === "yes",
    label: "informationForHealthcare.medicalAttentionProvided",
  })
  .withFieldAlwaysVisible({
    fieldName: "wasForceUsed",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "informationForHealthcare.wasForceUsed",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "describeForceUsed",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.wasForceUsed?.value === "yes",
    label: "informationForHealthcare.describeForceUsed",
  })
  .withFieldAlwaysVisible({
    fieldName: "hasPersonBeenSearched",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "informationForHealthcare.hasPersonBeenSearched",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "didSeizeproperty",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    isVisible({ formValues }) {
      return formValues.hasPersonBeenSearched?.value === "yes";
    },
    label: "informationForHealthcare.didSeizeproperty",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "detailsPropertySeized",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.didSeizeproperty?.value === "yes",
    label: "informationForHealthcare.detailsPropertySeized",
  })
  .withFieldAlwaysVisible({
    fieldName: "underInfluenceOfSubstance",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "informationForHealthcare.underInfluenceOfSubstance",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldAlwaysVisible({
    fieldName: "hasAdditionalRiskFactors",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "informationForHealthcare.hasAdditionalRiskFactors",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "describeRiskFactors",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.hasAdditionalRiskFactors?.value === "yes",
    label: "informationForHealthcare.describeRiskFactors",
  })
  .withPage(["incidentDescription"])
  .withPage(["wasHealthcareProvided", "medicalAttentionProvided"])
  .withPage(["wasForceUsed", "describeForceUsed"])
  .withPage([
    "hasPersonBeenSearched",
    "didSeizeproperty",
    "detailsPropertySeized",
  ])
  .withPage(["underInfluenceOfSubstance"])
  .withPage(["hasAdditionalRiskFactors", "describeRiskFactors"])
  .build();

export type InformationForHealthcareFormData =
  typeof formSpecification.formType;
