import { MultiPageFormBuilder } from "../../support/MultiPageFormBuilder/index.js";
import { valueAndLabelSchema, yesNoSchema } from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "hasRemainedAtPlaceOfSafety",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "recordPolicePresence.remainedAtPlaceOfSafety",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withPage(["hasRemainedAtPlaceOfSafety"])
  .build();

export type RecordPolicePresenceFormData = typeof formSpecification.formType;
