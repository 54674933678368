import { MultiPageFormBuilder } from "../../support/MultiPageFormBuilder/index.js";
import { valueAndLabelSchema, yesNoSchema } from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "hasPolicePresenceRequested",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withPage(["hasPolicePresenceRequested"])
  .build();

export type RequestPolicePresenceMainFormData =
  typeof formSpecification.formType;
