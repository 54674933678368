import React from "react";

import { Stack } from "@mui/material";
import {
  FormSection,
  IncidentFormIdSchema,
  IncidentFormType,
  IncidentWorkItemWithContext,
  RecordPlaceOfSafetyMinimumFields,
  WorkflowVersions,
} from "@vision/common";
import {
  Await,
  useNavigate,
  useParams,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import api from "../../api/index.js";
import Banner, { BannerList } from "../../components/Banner/Banner.js";
import {
  LeavePlaceOfSafetyForm,
  LeavePlaceOfSafetyFormSkeleton,
} from "../../forms/LeavePlaceOfSafety/LeavePlaceOfSafetyForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { IncidentWorkItemLoaderDeferredData } from "../../routing/loaders/incidentWorkItem.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export type LeavePlaceOfSafetyPageProps = Record<string, unknown>;

export function LeavePlaceOfSafetyPage(_props: LeavePlaceOfSafetyPageProps) {
  const navigate = useNavigate();
  const params = useParams();
  const revalidator = useRevalidator();

  const placeOfSafetyFormId = IncidentFormIdSchema.safeParse(params.formId);
  if (placeOfSafetyFormId.success === false) {
    throw new Error("Invalid form ID");
  }

  const { incidentWorkItem } = useRouteLoaderData(
    "incidentWorkItem",
  ) as IncidentWorkItemLoaderDeferredData;

  const [error, setError] = React.useState<string | undefined>(undefined);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }

    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem?.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      ?.workflowVersion as WorkflowVersions | undefined;

    const activePlaceOfSafetyName: string | undefined = (
      incidentWorkItem?.forms
        ?.find?.(
          (form) =>
            form.formType === IncidentFormType.recordPlaceOfSafety &&
            form.id === params.formId,
        )
        ?.sections?.find(
          (section) => section.id === FormSection.recordPlaceOfSafetyMain,
        )?.data as RecordPlaceOfSafetyMinimumFields | undefined
    )?.placeOfSafety?.name;

    const initialValues =
      incidentWorkItem?.forms
        ?.find?.(
          (form) =>
            form.formType === IncidentFormType.recordPlaceOfSafety &&
            form.id === params.formId,
        )
        ?.sections?.find(
          (section) => section.id === FormSection.recordPlaceOfSafetyLeave,
        )?.data ?? undefined;

    return (
      <Stack margin={"1rem"}>
        {activePlaceOfSafetyName !== undefined && (
          <Banner
            bannerType={BannerList.INFO}
            body={`The current Place of Safety is "${activePlaceOfSafetyName}"`}
          />
        )}
        {incidentWorkItemId === undefined || workflowVersion === undefined ? (
          <LeavePlaceOfSafetyFormSkeleton />
        ) : (
          <LeavePlaceOfSafetyForm
            initialValues={initialValues}
            onSubmit={async (values) => {
              try {
                if (incidentWorkItemId === undefined) {
                  return;
                }

                // Populate it with values
                const populateFormResponse =
                  await api.incidentWorkItem.updateData(
                    incidentWorkItemId,
                    placeOfSafetyFormId.data,
                    FormSection.recordPlaceOfSafetyLeave,
                    values,
                    true,
                  );
                if (populateFormResponse.status !== 204) {
                  throw Error(
                    JSON.stringify(populateFormResponse.data.message),
                  );
                }

                revalidator.revalidate();
                navigate(
                  createRoute.incidentRecordPlaceOfSafetyPage(
                    incidentWorkItemId,
                  ),
                );
                return;
              } catch (error) {
                setError(
                  "Failed to record leaving place of safety: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() =>
              navigate(createRoute.incidentProgressPage(incidentWorkItemId))
            }
            workflowVersion={workflowVersion}
          />
        )}
      </Stack>
    );
  };

  return (
    <React.Suspense fallback={render(undefined)}>
      <Await resolve={incidentWorkItem} errorElement={<ErrorComponent />}>
        {(resolvedData: IncidentWorkItemWithContext) => render(resolvedData)}
      </Await>
    </React.Suspense>
  );
}
