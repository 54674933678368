import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../api/index.js";
import { InitiateTransferModal } from "../modals/InitiateTransfer/InitiateTransfer.js";
import { createRoute } from "../routing/createRoute.js";
import { useToastNotifications } from "./useToastNotifications.js";

export const useInitiateTransferModal = () => {
  const [isInitiateTransferModalOpen, setIsInitiateTransferModalOpen] =
    useState(false);
  const [incidentWorkItemId, setIncidentWorkItemId] = useState<
    string | undefined
  >(undefined);
  const [notificationId, setNotificationId] = useState<string | undefined>(
    undefined,
  );
  const notifications = useToastNotifications();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openInitiateTransferModal = (
    incidentId: string,
    notificationId: string,
  ) => {
    setIncidentWorkItemId(incidentId);
    setNotificationId(notificationId);
    setIsInitiateTransferModalOpen(true);
  };

  const closeInitiateTransferModal = () => {
    setIsInitiateTransferModalOpen(false);
  };

  const handleConfirm = async (email: string) => {
    if (incidentWorkItemId === undefined || email === undefined) return;

    const createTransfer = {
      receivingOfficerEmail: email,
      incidentWorkItemId,
    };

    await api.incidentWorkItem
      .createTransfer(createTransfer)
      .then(async (res) => {
        if (res.status === 204) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const response = await api.notification.markAsRead(notificationId!);

          if (response.status !== 204) {
            notifications.show(res.data.reason, {
              severity: "error",
            });
          }

          notifications.show(
            t("transfer.initiateTransfer.confirmToast", { email }),
            {
              severity: "success",
            },
          );
          setIsInitiateTransferModalOpen(false);
          navigate(createRoute.home());
        } else {
          notifications.show(res.data.reason, {
            severity: "error",
          });
        }
      });
  };

  const InitiateTransferModalComponent = (
    <InitiateTransferModal
      open={isInitiateTransferModalOpen}
      onClose={closeInitiateTransferModal}
      incidentWorkItemId={incidentWorkItemId}
      onConfirm={handleConfirm}
    />
  );

  return {
    openInitiateTransferModal,
    closeInitiateTransferModal,
    InitiateTransferModalComponent,
  };
};
