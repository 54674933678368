import z, { ZodType } from "zod";
import { FieldBuilder } from "../support/MultiPageFormBuilder/FieldBuilder.js";
import { UserIdSchema, type UserIdUnbranded } from "./user.js";

export type IncidentFormIdUnbranded = string;

export const IncidentFormIdSchema = z.coerce
  .string()
  .uuid()
  .brand(Symbol("IncidentFormId")) satisfies ZodType<IncidentFormIdUnbranded>;

export type IncidentFormId = z.infer<typeof IncidentFormIdSchema>;

/**
 *
 * Incident Work Item
 *
 */

/**
 * An ID for an Incident Work Item
 */
export type IncidentWorkItemIdUnbranded = string;
export const IncidentWorkItemIdSchema = z.coerce
  .string()
  .uuid()
  .brand(
    Symbol("IncidentWorkItemId"),
  ) satisfies ZodType<IncidentWorkItemIdUnbranded>;
export type IncidentWorkItemId = z.infer<typeof IncidentWorkItemIdSchema>;

/**
 * An Incident Work Item
 */
export type IncidentWorkItemUnbranded = {
  id: IncidentWorkItemIdUnbranded;
  commandUnit: string;
  status: string;
  patientSurname: { value: string };
  patientForename: { value: string };
  dispatchNumber: { value: string };
  dispatchNumberCreatedAt?: Date;
  activePlaceOfSafetyFormId: IncidentFormIdUnbranded | null;
  workflowVersion: string;
  shareCode: string;
  createdAt: Date;
  updatedAt: Date;
};

export type IncidentWorkItemEnhancedUnbranded = IncidentWorkItemUnbranded & {
  commandUnitName: string;
};

export const IncidentWorkItemBaseSchema = z.object({
  id: IncidentWorkItemIdSchema,
  commandUnit: z.coerce.string().uuid(), // TODO - this should be a reference to a CommandUnit from the organisation
  status: z.string().max(50), // TODO - we need to define the statues for this ASP-2948
  patientSurname: new FieldBuilder().withSchema(z.string().max(255)).build()
    .schema,
  patientForename: new FieldBuilder().withSchema(z.string().max(255)).build()
    .schema,
  dispatchNumber: new FieldBuilder().withSchema(z.string().max(255)).build()
    .schema,
  dispatchNumberCreatedAt: z.coerce.date().optional(),
  activePlaceOfSafetyFormId: IncidentFormIdSchema.nullable(),
  workflowVersion: z.string().max(255),
  shareCode: z.string().regex(/^[A-Z0-9]{8}$/),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const IncidentWorkItemSchema = IncidentWorkItemBaseSchema.brand(
  Symbol("IncidentWorkItem"),
) satisfies ZodType<IncidentWorkItemUnbranded>;

export type IncidentWorkItem = z.infer<typeof IncidentWorkItemSchema>;

export const IncidentWorkItemEnhancedSchema = IncidentWorkItemBaseSchema.extend(
  {
    commandUnitName: z.string().max(255),
  },
).brand(
  Symbol("IncidentWorkItem"),
) satisfies ZodType<IncidentWorkItemUnbranded>;

export type IncidentWorkItemEnhanced = z.infer<
  typeof IncidentWorkItemEnhancedSchema
>;

/**
 *
 * Participant Work Item
 *
 */

/**
 * An ID for an Incident Work Item Participant
 */
export type IncidentWorkItemParticipantIdUnbranded = string;
export const IncidentWorkItemParticipantIdSchema = z
  .string()
  .uuid()
  .brand(
    Symbol("IncidentWorkItemParticipantId"),
  ) satisfies ZodType<IncidentWorkItemParticipantIdUnbranded>;
export type IncidentWorkItemParticipantId = z.infer<
  typeof IncidentWorkItemParticipantIdSchema
>;

export enum ParticipantRole {
  owner = "owner",
  pendingOwner = "pending-owner",
  participant = "participant",
  supervisor = "supervisor",
}

const ParticipantRoleEnum = z.nativeEnum(ParticipantRole);

export enum ParticipantStatus {
  active = "active",
  pendingTransfer = "pending-transfer",
  requestedTransfer = "requested-transfer",
  rejectedTransfer = "rejected-transfer",
  transferred = "transferred",
  revokedTransfer = "revoked-transfer",
}

const ParticipantStatusEnum = z.nativeEnum(ParticipantStatus);

/**
 * An Incident Work Item Participant
 */
export type IncidentWorkItemParticipantUnbranded = {
  id: IncidentWorkItemParticipantIdUnbranded;
  incidentWorkItemId: IncidentWorkItemParticipantIdUnbranded;
  participantId: UserIdUnbranded;
  status: ParticipantStatus;
  role: ParticipantRole;
  assignedAt: Date;
  unassignedAt?: Date | null;
};

export type IncidentWorkItemParticipantEnhancedUnbranded =
  IncidentWorkItemParticipantUnbranded & {
    name: string;
  };

const IncidentWorkItemParticipantSchemaBase = z.object({
  id: IncidentWorkItemParticipantIdSchema,
  incidentWorkItemId: IncidentWorkItemIdSchema,
  participantId: UserIdSchema,
  status: ParticipantStatusEnum,
  role: ParticipantRoleEnum,
  assignedAt: z.coerce.date(),
  unassignedAt: z.coerce
    .date()
    .transform((value) => (value.valueOf() === 0 ? undefined : value))
    .optional(),
});

export const IncidentWorkItemParticipantSchema =
  IncidentWorkItemParticipantSchemaBase.brand(
    Symbol("IncidentWorkItemParticipant"),
  ) satisfies ZodType<IncidentWorkItemParticipantUnbranded>;

export type IncidentWorkItemParticipant = z.infer<
  typeof IncidentWorkItemParticipantSchema
>;

export type IncidentFormSectionUnbranded = {
  id: string;
  incidentFormId: string;
  data: Record<string, unknown> | null;
  updatedAt: Date;
};

export type IncidentFormUnbranded = {
  id: string;
  incidentWorkItemId: string;
  status: string;
  formType: string;
  formDefinitionVersion: string;
  createdAt: Date;
  updatedAt: Date;
  sections: IncidentFormSectionUnbranded[];
};

export type IncidentWorkItemWithContextUnbranded = {
  incidentWorkItem: IncidentWorkItemEnhancedUnbranded;
  participants: IncidentWorkItemParticipantEnhancedUnbranded[];
  forms: IncidentFormUnbranded[];
};

export const IncidentWorkItemParticipantEnhancedSchema =
  IncidentWorkItemParticipantSchemaBase.extend({
    name: z.string().max(255),
  }).brand(
    Symbol("IncidentWorkItemParticipant"),
  ) satisfies ZodType<IncidentWorkItemParticipantEnhancedUnbranded>;

export type IncidentWorkItemParticipantEnhanced = z.infer<
  typeof IncidentWorkItemParticipantEnhancedSchema
>;

export enum IncidentFormStatus {
  active = "active",
  inactive = "inactive",
}

export const buildIncidentFormSectionSchema = (
  // Allow unknown keys on the 'generic' schema
  dataSchema = z.record(z.unknown()).nullable(),
) =>
  z
    .object({
      id: z.string(),
      incidentFormId: IncidentFormIdSchema,
      data: dataSchema,
      updatedAt: z.coerce.date(),
    })
    .brand(Symbol("IncidentFormSection")) satisfies ZodType<
    IncidentFormUnbranded["sections"][0]
  >;

// Add 5-6 etc later
export enum IncidentFormType {
  createIncident = "createIncident",
  triage = "triageIncident",
  recordPlaceOfSafety = "recordPlaceOfSafety",
  section136 = "136",
  preEndIncident = "preEndIncident",
  requestPolicePresence = "requestPolicePresence",
}

export const buildIncidentFormSchema = (
  sectionSchema = z.array(buildIncidentFormSectionSchema()),
) =>
  z
    .object({
      id: IncidentFormIdSchema,
      incidentWorkItemId: IncidentWorkItemIdSchema,
      status: z.nativeEnum(IncidentFormStatus),
      formType: z.nativeEnum(IncidentFormType),
      formDefinitionVersion: z.string(),
      createdAt: z.coerce.date(),
      updatedAt: z.coerce.date(),
      sections: sectionSchema,
    })
    .brand(Symbol("IncidentForm")) satisfies ZodType<IncidentFormUnbranded>;

export const IncidentWorkItemWithContextSchema = z.object({
  incidentWorkItem: IncidentWorkItemEnhancedSchema,
  participants: z.array(IncidentWorkItemParticipantEnhancedSchema),
  forms: z.array(buildIncidentFormSchema()),
});

export type IncidentWorkItemWithContext = z.infer<
  typeof IncidentWorkItemWithContextSchema
>;

export type IncidentForm = IncidentWorkItemWithContext["forms"][0];
export type IncidentFormSection = IncidentForm["sections"][0];

export type IncidentWorkItemResponse = {
  incidentWorkItem: IncidentWorkItemEnhancedUnbranded;
  participants: IncidentWorkItemParticipantEnhancedUnbranded[];
  forms: IncidentFormUnbranded[];
};

export type GetIncidentWorkItemResponse = {
  workItems: IncidentWorkItemResponse[];
};

export type CreateIncidentWorkItemRequest = Omit<
  IncidentWorkItemUnbranded,
  | "id"
  | "createdAt"
  | "updatedAt"
  | "status"
  | "workflowVersion"
  | "shareCode"
  | "activePlaceOfSafetyFormId"
  | "dispatchNumberCreatedAt"
> & {
  dispatchNumberCreatedAt?: string;
};

export const GetIncidentWorkItemResponseSchema = z
  .object({
    workItems: z.array(IncidentWorkItemWithContextSchema),
  })
  .brand(
    Symbol("GetIncidentWorkItemResponse"),
  ) satisfies ZodType<GetIncidentWorkItemResponse>;

export type GetIncidentWorkItemResponseBranded = z.infer<
  typeof GetIncidentWorkItemResponseSchema
>;
