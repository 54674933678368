import { z } from "zod";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  dateTimeSchema,
  freeTextSchema,
  valueAndLabelSchema,
  yesNoSchema,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "arrivalDateTime",
    type: "date-time",
    schema: dateTimeSchema,
    dateLabel: "arrivePlaceOfSafety.arrivalDate",
    timeLabel: "arrivePlaceOfSafety.arrivalTime",
  })
  .withFieldAlwaysVisible({
    fieldName: "wasEntryDelayed",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    options: [
      { label: "common.yes", value: "yes" },
      { label: "common.no", value: "no" },
    ],
    label: "arrivePlaceOfSafety.wasEntryDelayed",
  })
  .withFieldConditionallyVisible({
    fieldName: "whyWasEntryDelayed",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.wasEntryDelayed?.value === "yes",
    label: "arrivePlaceOfSafety.whyWasEntryDelayed",
  })
  .withFieldConditionallyVisible({
    fieldName: "howLongWasEntryDelayed",
    type: "dropdown",
    schema: valueAndLabelSchema(
      z.enum([
        "0-15" as const,
        "15-30" as const,
        "30-60" as const,
        "60+" as const,
      ]),
    ),
    options: [
      {
        label: "arrivePlaceOfSafety.howLongWasEntryDelayed.options.0-15",
        value: "0-15",
      },
      {
        label: "arrivePlaceOfSafety.howLongWasEntryDelayed.options.15-30",
        value: "15-30",
      },
      {
        label: "arrivePlaceOfSafety.howLongWasEntryDelayed.options.30-60",
        value: "30-60",
      },
      {
        label: "arrivePlaceOfSafety.howLongWasEntryDelayed.options.60+",
        value: "60+",
      },
    ],
    isVisible: ({ formValues }) => formValues.wasEntryDelayed?.value === "yes",
    label: "arrivePlaceOfSafety.howLongWasEntryDelayed.title",
  })
  .withFieldAlwaysVisible({
    fieldName: "methodOfTransport",
    type: "dropdown",
    schema: valueAndLabelSchema(
      z.enum([
        "alreadyAtPos" as const,
        "ambulance" as const,
        "policeVehicle" as const,
        "otherHealthVehicle" as const,
        "escorted" as const,
        "other" as const,
      ]),
    ),
    options: [
      {
        label: "arrivePlaceOfSafety.methodOfTransport.options.alreadyAtPos",
        value: "alreadyAtPos",
      },
      {
        label: "arrivePlaceOfSafety.methodOfTransport.options.ambulance",
        value: "ambulance",
      },
      {
        label: "arrivePlaceOfSafety.methodOfTransport.options.policeVehicle",
        value: "policeVehicle",
      },
      {
        label:
          "arrivePlaceOfSafety.methodOfTransport.options.otherHealthVehicle",
        value: "otherHealthVehicle",
      },
      {
        label: "arrivePlaceOfSafety.methodOfTransport.options.escorted",
        value: "escorted",
      },
      {
        label: "arrivePlaceOfSafety.methodOfTransport.options.other",
        value: "other",
      },
    ],
    label: "arrivePlaceOfSafety.methodOfTransport.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "methodOfTransportOther",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.methodOfTransport?.value === "other",
    label: "arrivePlaceOfSafety.methodOfTransportOther.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "whyMethodOfTransportPoliceVehicle",
    type: "dropdown",
    schema: valueAndLabelSchema(
      z.enum([
        "ambulanceAvailableInTimeframe" as const,
        "ambulanceCrewRefused" as const,
        "riskAssessment" as const,
        "ambulanceNotRequested" as const,
        "ambulanceRetasked" as const,
        "other" as const,
      ]),
    ),
    options: [
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.ambulanceAvailableInTimeframe",
        value: "ambulanceAvailableInTimeframe",
      },
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.ambulanceCrewRefused",
        value: "ambulanceCrewRefused",
      },
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.riskAssessment",
        value: "riskAssessment",
      },
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.ambulanceNotRequested",
        value: "ambulanceNotRequested",
      },
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.ambulanceRetasked",
        value: "ambulanceRetasked",
      },
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.other",
        value: "other",
      },
    ],
    isVisible: ({ formValues }) =>
      formValues.methodOfTransport?.value === "policeVehicle",
    label: "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "whyMethodOfTransportPoliceVehicleOther",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.methodOfTransport?.value === "policeVehicle" &&
      formValues.whyMethodOfTransportPoliceVehicle?.value === "other",
    label: "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicleOther.title",
  })

  .withPage(["arrivalDateTime"])
  .withPage(["wasEntryDelayed", "whyWasEntryDelayed", "howLongWasEntryDelayed"])
  .withPage([
    "methodOfTransport",
    "methodOfTransportOther",
    "whyMethodOfTransportPoliceVehicle",
    "whyMethodOfTransportPoliceVehicleOther",
  ])
  .build();

export type ArrivePlaceOfSafetyFormData = typeof formSpecification.formType;
