import type { ActionInput } from "../ActionInput.js";
import {
  incidentHasTransferRequest,
  incidentIsActive,
  userIsIncidentOwner,
} from "../helpers.js";

type InitiateTransferAction = {
  canInitiate: boolean;
};

type RevokeTransferAction = {
  canRevoke: boolean;
};

type AcceptRejectTransferAction = {
  canAcceptReject: boolean;
};

type TransferActions = InitiateTransferAction &
  RevokeTransferAction &
  AcceptRejectTransferAction;

function incidentStatusAllowsTransfer(actionInput: ActionInput): boolean {
  const { incidentWorkItem } = actionInput;
  return (
    incidentIsActive(actionInput) ||
    incidentWorkItem.incidentWorkItem.status === "unknown"
  );
}

function canInitiateTransfer(actionInput: ActionInput): boolean {
  return (
    incidentStatusAllowsTransfer(actionInput) &&
    userIsIncidentOwner(actionInput) &&
    // can't initiate if there's already a transfer request
    !incidentHasTransferRequest(actionInput)
  );
}

function canRevokeTransfer(actionInput: ActionInput): boolean {
  return incidentIsActive(actionInput) && userIsIncidentOwner(actionInput);
}

function canAcceptRejectTransfer(actionInput: ActionInput): boolean {
  return (
    incidentIsActive(actionInput) &&
    // can't accept/reject if user is the owner
    !userIsIncidentOwner(actionInput)
  );
}

export function calculateTransferActions(
  actionInput: ActionInput,
): TransferActions {
  return {
    canInitiate: canInitiateTransfer(actionInput),
    canRevoke: canRevokeTransfer(actionInput),
    canAcceptReject: canAcceptRejectTransfer(actionInput),
  };
}
