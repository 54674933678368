import { type ZodType, type ZodTypeAny } from "zod";
import type { TranslationKey } from "../@types/react-i18next.js";
import type { VisionPdfTemplate } from "./VisionPdfTemplate.js";
import type { FormBuilderField } from "./fields.js";
import type {
  EthnicityType,
  GenderType,
  SelfDeclaredEthnicityType,
} from "./formFieldSchemas.js";
import type {
  IncidentFormType,
  IncidentWorkItemWithContextUnbranded,
} from "./workItem.js";
import type { WorkflowVersions } from "./workflowVersion.js";

export type Value<T> = {
  value: T;
};

export type ValueAndLabel<T> = {
  value: T;
  label: string;
};

export type DetentionDetailsMinimumFields = {
  custodyNumber: Value<string> | null;
  dateTimeOfDetention: string;
  responsibleForDetention: Value<string>;
  detentionTookPlace: Value<string>;
};

export type CreateIncidentMinimumFields = {
  dispatchNumber: Value<string>;
  patientForename: Value<string>;
  patientSurname: Value<string>;
  commandUnit: string;
};

export type TriageIncidentMinimumFields = {
  isHealthcareProfessionalConsulted: ValueAndLabel<"yes" | "no">;
  whatWillYouDo: ValueAndLabel<"136" | "5-6" | "voluntary" | "other">;
};

export type PersonDetailsMinimumFields = {
  patientAddress: Value<string>;
  dateOfBirth: Value<string>;
  gender: ValueAndLabel<GenderType>;
  ethnicAppearance: ValueAndLabel<EthnicityType>;
  selfDeclaredEthnicity: ValueAndLabel<SelfDeclaredEthnicityType>;
  hasRelativeOrFriend: ValueAndLabel<"yes" | "no">;
};

export type InformationForHealthcareMinimumFields = {
  wasHealthcareProvided: ValueAndLabel<"yes" | "no">;
  hasPersonBeenSearched: ValueAndLabel<"yes" | "no">;
  hasAdditionalRiskFactors: ValueAndLabel<"yes" | "no">;
};

export type RecordPlaceOfSafetyMinimumFields = {
  placeOfSafety: {
    aspirePlaceOfSafetyId?: string;
    name: string;
    address: string;
    postalCode: string;
  };
};

export type RecordPlaceOfSafetyArrivalMinimumFields = {
  arrivalDateTime: string;
};

export type RecordPlaceOfSafetyLeaveMinimumFields = {
  leaveReason: ValueAndLabel<
    | "advised_visit_Hbpos_because_bedNowAvailable"
    | "advised_visit_accidentAndEmergency_because_noHbposAvailable"
    | "advised_visit_accidentAndEmergency_because_medicalGrounds"
    | "decided_visit_accidentAndEmergency_because_UnreasonableDelay"
    | "physicalHealthCheckComplete"
    | "other"
  >;
  leaveReasonOther: Value<string> | null;
};

export type RecordPreEndIncidentMinimumFields = {
  endingReason: ValueAndLabel<
    | "startedFormInError"
    | "formExpired"
    | "nhsNotEngage"
    | "medicalExamination"
    | "other"
  >;
};

export type RequestPolicePresenceMinimumFields = {
  hasPolicePresenceRequested: ValueAndLabel<"yes" | "no">;
};

export type RecordPolicePresenceMinimumFields = {
  hasRemainedAtPlaceOfSafety: ValueAndLabel<"yes" | "no">;
};

export enum FormSection {
  personDetails = "personDetails",
  detentionDetails = "detentionDetails",
  informationForHealthcare = "informationForHealthcare",
  triageMain = "triageMain",
  createIncidentMain = "createIncidentMain",
  recordPlaceOfSafetyMain = "recordPlaceOfSafetyMain",
  recordPlaceOfSafetyArrival = "recordPlaceOfSafetyArrival",
  recordPlaceOfSafetyLeave = "recordPlaceOfSafetyLeave",
  preEndIncident = "preEndIncident",
  requestPolicePresenceMain = "requestPolicePresenceMain",
  recordPolicePresence = "recordPolicePresence",
}

export type MinimumFieldsPerSection = {
  [FormSection.personDetails]: PersonDetailsMinimumFields;
  [FormSection.detentionDetails]: DetentionDetailsMinimumFields;
  [FormSection.informationForHealthcare]: InformationForHealthcareMinimumFields;
  [FormSection.triageMain]: TriageIncidentMinimumFields;
  [FormSection.createIncidentMain]: CreateIncidentMinimumFields;
  [FormSection.recordPlaceOfSafetyMain]: RecordPlaceOfSafetyMinimumFields;
  [FormSection.recordPlaceOfSafetyArrival]: RecordPlaceOfSafetyArrivalMinimumFields;
  [FormSection.recordPlaceOfSafetyLeave]: RecordPlaceOfSafetyLeaveMinimumFields;
  [FormSection.preEndIncident]: RecordPreEndIncidentMinimumFields;
  [FormSection.requestPolicePresenceMain]: RequestPolicePresenceMinimumFields;
  [FormSection.recordPolicePresence]: RecordPolicePresenceMinimumFields;
};

export type IncidentFormSections = {
  [IncidentFormType.createIncident]: FormSection.createIncidentMain;
  [IncidentFormType.triage]: FormSection.triageMain;
  [IncidentFormType.recordPlaceOfSafety]:
    | FormSection.recordPlaceOfSafetyMain
    | FormSection.recordPlaceOfSafetyArrival
    | FormSection.recordPlaceOfSafetyLeave;
  [IncidentFormType.section136]:
    | FormSection.personDetails
    | FormSection.detentionDetails
    | FormSection.informationForHealthcare;
  [IncidentFormType.preEndIncident]: FormSection.preEndIncident;
  [IncidentFormType.requestPolicePresence]:
    | FormSection.requestPolicePresenceMain
    | FormSection.recordPolicePresence;
};

export type FormSectionDefinition<
  Section extends FormSection,
  DataType extends MinimumFieldsPerSection[Section],
> = {
  id: Section;
  name: TranslationKey;
  strictFieldSchemas: Record<string, ZodTypeAny>;
  formSchema: ZodType<DataType>;
  autosave: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pages: { fields: readonly FormBuilderField<DataType, any>[] }[];
};

export type FormDefinition<
  SectionDefinitions extends FormSectionDefinition<
    FormSection,
    // Couldn't figure out how to avoid the any here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >[],
> = {
  sections: SectionDefinitions;
};

export type WorkflowPdfInputData<
  TTriageIncident extends TriageIncidentMinimumFields,
  TPersonDetails extends Partial<PersonDetailsMinimumFields>,
  TDetentionDetails extends Partial<DetentionDetailsMinimumFields>,
  TInformationForHealthcare extends
    Partial<InformationForHealthcareMinimumFields>,
> = {
  triage: TTriageIncident;

  section136: {
    personDetails: TPersonDetails;
    detentionDetails: TDetentionDetails;
    informationForHealthcare: TInformationForHealthcare;
  };

  incidentWorkItem: IncidentWorkItemWithContextUnbranded;
  // TODO: other forms/sections and metadata
};

export type WorkflowPdfTemplate<
  TTriageIncident extends TriageIncidentMinimumFields,
  TPersonDetails extends Partial<PersonDetailsMinimumFields>,
  TDetentionDetails extends Partial<DetentionDetailsMinimumFields>,
  TInformationForHealthcare extends
    Partial<InformationForHealthcareMinimumFields>,
> = VisionPdfTemplate<
  WorkflowPdfInputData<
    TTriageIncident,
    TPersonDetails,
    TDetentionDetails,
    TInformationForHealthcare
  >
>;

export type Workflow<
  TCreateIncident extends CreateIncidentMinimumFields,
  TTriageIncident extends TriageIncidentMinimumFields,
  TPersonDetails extends PersonDetailsMinimumFields,
  TDetentionDetails extends DetentionDetailsMinimumFields,
  TInformationForHealthcare extends InformationForHealthcareMinimumFields,
  TRecordPlaceOfSafety extends RecordPlaceOfSafetyMinimumFields,
  TRecordPlaceOfSafetyArrival extends RecordPlaceOfSafetyArrivalMinimumFields,
  TRecordPlaceOfSafetyLeave extends RecordPlaceOfSafetyLeaveMinimumFields,
  TRecordPreEndIncident extends RecordPreEndIncidentMinimumFields,
  TRequestPolicePresence extends RequestPolicePresenceMinimumFields,
  TRecordPolicePresence extends RecordPolicePresenceMinimumFields,
> = {
  workflowVersion: WorkflowVersions;
  [IncidentFormType.createIncident]: {
    formDefinition: FormDefinition<
      [FormSectionDefinition<FormSection.createIncidentMain, TCreateIncident>]
    >;
  };
  [IncidentFormType.triage]: {
    formDefinition: FormDefinition<
      [FormSectionDefinition<FormSection.triageMain, TTriageIncident>]
    >;
  };
  [IncidentFormType.recordPlaceOfSafety]: {
    formDefinition: FormDefinition<
      [
        FormSectionDefinition<
          FormSection.recordPlaceOfSafetyMain,
          TRecordPlaceOfSafety
        >,
        FormSectionDefinition<
          FormSection.recordPlaceOfSafetyArrival,
          TRecordPlaceOfSafetyArrival
        >,
        FormSectionDefinition<
          FormSection.recordPlaceOfSafetyLeave,
          TRecordPlaceOfSafetyLeave
        >,
      ]
    >;
  };
  [IncidentFormType.preEndIncident]: {
    formDefinition: FormDefinition<
      [FormSectionDefinition<FormSection.preEndIncident, TRecordPreEndIncident>]
    >;
  };

  [IncidentFormType.requestPolicePresence]: {
    formDefinition: FormDefinition<
      [
        FormSectionDefinition<
          FormSection.requestPolicePresenceMain,
          TRequestPolicePresence
        >,
        FormSectionDefinition<
          FormSection.recordPolicePresence,
          TRecordPolicePresence
        >,
      ]
    >;
  };

  [IncidentFormType.section136]: {
    formDefinition: FormDefinition<
      [
        FormSectionDefinition<FormSection.personDetails, TPersonDetails>,
        FormSectionDefinition<FormSection.detentionDetails, TDetentionDetails>,
        FormSectionDefinition<
          FormSection.informationForHealthcare,
          TInformationForHealthcare
        >,
      ]
    >;

    pdfTemplate: WorkflowPdfTemplate<
      TTriageIncident,
      TPersonDetails,
      TDetentionDetails,
      TInformationForHealthcare
    >;
  };
};
