import { Done, InfoOutlined, WarningAmberOutlined } from "@mui/icons-material";
import { Box, Button, Theme, Typography, useTheme } from "@mui/material";
import React from "react";

export enum ActionBannerList {
  INFO = "info",
  WARNING = "warning",
  SUCCESS = "success",
}

type BannerProperties = {
  borderColor: (theme: Theme) => string;
  icon: (theme: Theme) => React.ReactNode;
  backgroundColor?: (theme: Theme) => string;
};

const bannerTypeMapping: Record<ActionBannerList, BannerProperties> = {
  [ActionBannerList.INFO]: {
    borderColor: (theme: Theme) => theme.palette.common.lightBlue,
    icon: (theme: Theme) => (
      <InfoOutlined
        sx={{
          color: theme.palette.common.lightBlue,
          fontSize: "32px",
        }}
      />
    ),
  },
  [ActionBannerList.SUCCESS]: {
    borderColor: (theme: Theme) => theme.palette.success.main,
    icon: (theme: Theme) => (
      <Done
        sx={{
          color: theme.palette.success.main,
          fontSize: "32px",
        }}
      />
    ),
  },
  [ActionBannerList.WARNING]: {
    borderColor: (theme: Theme) => theme.palette.warning.main,
    backgroundColor: (theme: Theme) => theme.palette.warning.light,
    icon: (theme: Theme) => (
      <WarningAmberOutlined
        sx={{
          color: theme.palette.warning.main,
          fontSize: "32px",
        }}
      />
    ),
  },
};

interface ActionBannerProps {
  title: string;
  bannerType: ActionBannerList;
  onClick?: () => void;
}

const getBannerProperties = (bannerType: ActionBannerList, theme: Theme) => {
  const { borderColor, icon, backgroundColor } = bannerTypeMapping[bannerType];
  return {
    borderColor: borderColor(theme),
    icon: icon(theme),
    backgroundColor: backgroundColor ? backgroundColor(theme) : undefined,
  };
};

export default function Banner({
  title,
  bannerType,
  onClick,
}: ActionBannerProps) {
  const theme = useTheme();

  const { borderColor, icon, backgroundColor } = getBannerProperties(
    bannerType,
    theme,
  );

  return (
    <Box
      sx={{
        border: `1px solid ${borderColor}`,
        backgroundColor: backgroundColor ?? "transparent",
      }}
    >
      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>{icon}</Box>
        <Typography>{title}</Typography>
        {onClick && (
          <Button
            data-testid="action-link"
            onClick={onClick}
            variant="contained"
          >
            View
          </Button>
        )}
      </Box>
    </Box>
  );
}
