import React, { useCallback } from "react";

import {
  FormControl,
  FormControlLabel,
  Checkbox as MUICheckbox,
} from "@mui/material";
import { TranslationKey } from "@vision/common";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FormError } from "../FormError/FormError.js";

export type CheckboxProps = {
  fieldName: string;
  label: TranslationKey;
};

type SelectedField = { value: string; label: string };

export const Checkbox = ({ fieldName, label }: CheckboxProps) => {
  const { t } = useTranslation();

  const [field, meta, helpers] = useField<SelectedField | "">(fieldName);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      helpers.setValue(
        event.target.checked ? { value: "yes", label: t(label) } : "",
      );
      helpers.setTouched(true);
    },
    [helpers, label, t],
  );

  return (
    <FormControl error={!!(meta.touched && meta.error)} component="fieldset">
      <FormControlLabel
        control={
          <MUICheckbox
            checked={field.value !== "" && field.value.value === "yes"}
            onChange={handleChange}
            name={field.name}
          />
        }
        label={label && t(label)}
      />
      {meta.touched && meta.error && <FormError error={meta.error} />}
    </FormControl>
  );
};
