import z, { ZodType } from "zod";
import type { TranslationKey } from "../@types/react-i18next.js";
import type { OrganisationRoles } from "./organisation.js";

/**
 * An Email Address
 */
export type EmailUnbranded = string;
export const EmailSchema = z
  .string()
  .email()
  .brand(Symbol("Email")) satisfies ZodType<EmailUnbranded>;
export type Email = z.infer<typeof EmailSchema>;

/**
 * A function that determines the visibility of a field based on the value of other fields.
 *
 * @param args.formValues The form values
 * @returns True if the field should be visible, false otherwise.
 */
export type FieldVisibilityFn<Form extends Record<string, unknown>> = (args: {
  formValues: Partial<Form>;
}) => boolean;

export type BaseFormBuilderField<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
> = {
  fieldName: Field & string;
  label?: TranslationKey;
  visibilityFn?: FieldVisibilityFn<Form>;
  canBeUnknown?: boolean;
  tooltip?: {
    title: TranslationKey;
    content: TranslationKey;
  };
};

export type BaseFormBuilderReadonlyField<
  Form extends Record<string, unknown>,
  Field extends string,
> = {
  fieldName: Field & string;
  label?: TranslationKey;
  visibilityFn?: FieldVisibilityFn<Form>;
  tooltip?: {
    title: TranslationKey;
    content: TranslationKey;
  };
};

export type DateFormBuilderField<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
> = BaseFormBuilderField<Form, Field> & {
  type: "date";
  afterLabel?: TranslationKey;
  // minimum?: (today: Dayjs) => Dayjs;
  // maximum?: (today: Dayjs) => Dayjs;
  // warningFn?: (selectedDate: Dayjs) => string | null;
  // disabled?: boolean;
};

export type DropdownFormBuilderField<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
> = BaseFormBuilderField<Form, Field> & {
  type: "dropdown";
  options: {
    label: TranslationKey;
    value: string;
  }[];
};

export type TimeFormBuilderField<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
> = BaseFormBuilderField<Form, Field> & {
  type: "time";
  afterLabel?: TranslationKey;
};

export type TextboxFormBuilderField<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
> = BaseFormBuilderField<Form, Field> & {
  type: "textbox";
  afterLabel?: TranslationKey;
  rows?: string;
};

export type CheckboxFormBuilderField<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
> = Omit<BaseFormBuilderField<Form, Field>, "label"> & {
  type: "checkbox";
  label: TranslationKey;
  afterLabel?: TranslationKey;
};

export type OrganisationFormBuilderField<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
> = BaseFormBuilderField<Form, Field> & {
  type: "organisation";
  options: (data: Form) => {
    id: string;
    name: string;
    managingOrganisationId: string | null;
    role: OrganisationRoles;
  }[];
  filterByRoles?: OrganisationRoles[];
};

export type PlaceOfSafetyFormBuilderField<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
> = BaseFormBuilderField<Form, Field> & {
  type: "placeOfSafety";
};

export type TypographyFormBuilderField<
  Form extends Record<string, unknown>,
  Field extends string,
> = BaseFormBuilderReadonlyField<Form, Field> & {
  type: "typography";
  label?: TranslationKey;
  textFn?: (data: Form) => (string | TranslationKey)[];
};

export type RadioFormBuilderField<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
> = BaseFormBuilderField<Form, Field> & {
  type: "radio";
  options: {
    label: TranslationKey;
    value: string;
  }[];
};

export type DateTimeFormBuilderField<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
> = BaseFormBuilderField<Form, Field> & {
  type: "date-time";
  dateLabel: TranslationKey;
  timeLabel: TranslationKey;
};

export type InfoBannerField<
  Form extends Record<string, unknown>,
  Field extends string,
> = BaseFormBuilderReadonlyField<Form, Field> & {
  type: "info-banner";
  label: TranslationKey;
};

export type MultiSelectField<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
> = BaseFormBuilderField<Form, Field> & {
  type: "multi-select";
  label: TranslationKey;
  options: {
    label: TranslationKey;
    value: string;
  }[];
};

export type FormBuilderWritableField<
  Form extends Record<string, unknown>,
  Field extends keyof Form & string,
> =
  | DateFormBuilderField<Form, Field>
  | OrganisationFormBuilderField<Form, Field>
  | PlaceOfSafetyFormBuilderField<Form, Field>
  | TextboxFormBuilderField<Form, Field>
  | TimeFormBuilderField<Form, Field>
  | DropdownFormBuilderField<Form, Field>
  | RadioFormBuilderField<Form, Field>
  | DateTimeFormBuilderField<Form, Field>
  | CheckboxFormBuilderField<Form, Field>
  | MultiSelectField<Form, Field>;

export type FormBuilderReadonlyField<
  Form extends Record<string, unknown>,
  Field extends string,
> = TypographyFormBuilderField<Form, Field> | InfoBannerField<Form, Field>;

export type FormBuilderField<
  Form extends Record<string, unknown>,
  Field extends keyof Form & string,
> =
  | FormBuilderWritableField<Form, Field>
  | FormBuilderReadonlyField<Form, Field>;
