import { Box, Chip, Skeleton, useTheme } from "@mui/material";
import {
  IncidentWorkItemParticipant,
  IncidentWorkItemWithContext,
  ParticipantRole,
  ParticipantStatus,
  TranslationKey,
  UserIdUnbranded,
} from "@vision/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useRevalidator } from "react-router-dom";
import api from "../../api/index.js";
import {
  EmptyIncidentWorkItemCard,
  IncidentWorkItemCard,
  TypographyI18N,
} from "../../components/index.js";
import { useToastNotifications } from "../../hooks/useToastNotifications.js";
import { createRoute } from "../../routing/createRoute.js";

export type IncidentListProps = {
  incidentWorkItems: IncidentWorkItemWithContext[];
  isLoading: boolean;
  mode: "myIncidents" | "transferredIncidents";
  userId: UserIdUnbranded | undefined;
};

export const IncidentList = ({
  incidentWorkItems,
  isLoading,
  mode,
  userId,
}: IncidentListProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const notifications = useToastNotifications();

  const modeConfig = {
    myIncidents: {
      showAssignedOfficerOnMobile: false,
      titleKey: "incidentWorkItem.myIncidents" as TranslationKey,
      emptyKey: "incidentWorkItem.yourIncidentsHere" as TranslationKey,
      backgroundColor: theme.palette.warning.dark,
    },
    transferredIncidents: {
      showAssignedOfficerOnMobile: true,
      titleKey: "incidentWorkItem.transferredIncidents" as TranslationKey,
      emptyKey: "incidentWorkItem.transferredIncidentsHere" as TranslationKey,
      backgroundColor: theme.palette.error.main,
    },
  };

  const { showAssignedOfficerOnMobile, titleKey, emptyKey, backgroundColor } =
    modeConfig[mode];

  const getMatchingParticipant = (incident: IncidentWorkItemWithContext) =>
    incident.participants.find((p) => p.participantId === userId);

  const isPendingTransfer = (participant?: IncidentWorkItemParticipant) =>
    participant?.status === ParticipantStatus.pendingTransfer &&
    participant?.role === ParticipantRole.pendingOwner;

  const canCancelTransfer = (participant?: IncidentWorkItemParticipant) =>
    participant?.status === ParticipantStatus.requestedTransfer &&
    participant?.role === ParticipantRole.owner;

  const handleViewIncident = (
    incident: IncidentWorkItemWithContext,
    matchingParticipant?: IncidentWorkItemParticipant,
  ) => {
    if (isPendingTransfer(matchingParticipant)) {
      api.incidentWorkItem
        .resolveTransfer({
          incidentWorkItemId: incident.incidentWorkItem.id,
          isAccepted: true,
        })
        .then((res) => {
          if (res.status === 204) {
            notifications.show(t("incidentWorkItem.notificationAcceptedShow"), {
              severity: "success",
            });
            navigate(
              createRoute.incidentProgressPage(incident.incidentWorkItem.id),
            );
          } else {
            notifications.show(
              t("incidentWorkItem.notificationAcceptedError"),
              { severity: "error" },
            );
          }
        });
    } else {
      navigate(createRoute.incidentProgressPage(incident.incidentWorkItem.id));
    }
  };

  const handleSecondaryAction = (
    incident: IncidentWorkItemWithContext,
    matchingParticipant?: IncidentWorkItemParticipant,
  ) => {
    if (canCancelTransfer(matchingParticipant)) {
      api.incidentWorkItem
        .revokeTransfer({ incidentWorkItemId: incident.incidentWorkItem.id })
        .then((res) => {
          if (res.status === 204) {
            notifications.show(
              t("incidentWorkItem.notificationCancelledShow"),
              { severity: "success" },
            );
            revalidator.revalidate();
          } else {
            notifications.show(
              t("incidentWorkItem.notificationCancelledError"),
              { severity: "error" },
            );
          }
        });
    } else if (isPendingTransfer(matchingParticipant)) {
      api.incidentWorkItem
        .resolveTransfer({
          incidentWorkItemId: incident.incidentWorkItem.id,
          isAccepted: false,
        })
        .then((res) => {
          if (res.status === 204) {
            notifications.show(t("incidentWorkItem.notificationRejectedShow"), {
              severity: "success",
            });
            revalidator.revalidate();
          } else {
            notifications.show(
              t("incidentWorkItem.notificationRejectedError"),
              { severity: "error" },
            );
          }
        });
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
      <Box sx={{ display: "flex" }}>
        <TypographyI18N translationKey={titleKey} />
        <Chip
          data-testid="incident-list-count"
          label={isLoading ? ".." : incidentWorkItems.length}
          size="small"
          sx={{
            backgroundColor,
            color: theme.palette.warning.contrastText,
            marginLeft: "0.5rem",
          }}
        />
      </Box>
      {isLoading ? (
        <Skeleton
          data-testid="incident-list-skeleton"
          sx={{
            padding: 0,
            width: "100%",
            [theme.breakpoints.down("md")]: {
              minHeight: "10rem",
            },
            [theme.breakpoints.up("md")]: {
              minHeight: "3rem",
            },
            transform: "unset",
          }}
        />
      ) : incidentWorkItems.length > 0 ? (
        <>
          {incidentWorkItems.map((incident) => {
            const matchingParticipant = getMatchingParticipant(incident);
            const isPending = isPendingTransfer(matchingParticipant);
            const canCancel = canCancelTransfer(matchingParticipant);

            const showSecondaryBtn = canCancel || isPending;

            return (
              <IncidentWorkItemCard
                key={incident.incidentWorkItem.id}
                incidentWorkItem={incident}
                onViewIncident={() =>
                  handleViewIncident(incident, matchingParticipant)
                }
                primaryButtonLabel={
                  isPending
                    ? "incidentWorkItem.acceptTransfer"
                    : "incidentWorkItem.view"
                }
                onSecondaryBtn={() =>
                  handleSecondaryAction(incident, matchingParticipant)
                }
                showSecondaryBtn={showSecondaryBtn}
                secondaryLabel={
                  isPending
                    ? "incidentWorkItem.reject"
                    : "incidentWorkItem.cancel"
                }
                showAssignedOfficerOnMobile={showAssignedOfficerOnMobile}
                isPendingTransfer={isPending}
              />
            );
          })}
        </>
      ) : (
        <EmptyIncidentWorkItemCard title={emptyKey} />
      )}
    </Box>
  );
};
