import React from "react";

import {
  FormSection,
  IncidentFormType,
  IncidentWorkItemWithContext,
  RequestPolicePresenceMinimumFields,
  WorkflowVersions,
} from "@vision/common";
import {
  Await,
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import api from "../../api/index.js";
import {
  RecordPolicePresenceForm,
  RecordPolicePresenceFormSkeleton,
} from "../../forms/RecordPolicePresence/RecordPolicePresenceForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { IncidentWorkItemLoaderDeferredData } from "../../routing/loaders/incidentWorkItem.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export type RecordPolicePresencePageProps = Record<string, unknown>;

export function RecordPolicePresencePage(
  _props: RecordPolicePresencePageProps,
) {
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const { incidentWorkItem } = useRouteLoaderData(
    "incidentWorkItem",
  ) as IncidentWorkItemLoaderDeferredData;
  const [error, setError] = React.useState<string | undefined>(undefined);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }

    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem?.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      ?.workflowVersion as WorkflowVersions | undefined;

    // TODO - delete this - (just a placeholder to show the police presence banner) need work out the active police presence form ASP-2965
    const nhsRequestedPolicePresence = incidentWorkItem?.forms.find(
      (f) =>
        f.formType === IncidentFormType.requestPolicePresence &&
        f.sections.some(
          (s) =>
            s.id === FormSection.requestPolicePresenceMain &&
            (s.data as RequestPolicePresenceMinimumFields | undefined)
              ?.hasPolicePresenceRequested.value === "yes",
        ),
    );

    return (
      <>
        {incidentWorkItemId === undefined || workflowVersion === undefined ? (
          <RecordPolicePresenceFormSkeleton />
        ) : (
          <RecordPolicePresenceForm
            onSubmit={async (values) => {
              try {
                if (
                  incidentWorkItemId === undefined ||
                  nhsRequestedPolicePresence === undefined
                ) {
                  return;
                }
                const response = await api.incidentWorkItem.updateData(
                  incidentWorkItemId,
                  nhsRequestedPolicePresence?.id,
                  FormSection.recordPolicePresence,
                  values,
                  true,
                );
                if (response.status === 204) {
                  revalidator.revalidate();
                  navigate(`/incident/${incidentWorkItemId}/section-complete`, {
                    state: {
                      type: FormSection.recordPolicePresence,
                    },
                  });
                  return;
                } else {
                  setError(JSON.stringify(response.data.message));
                }
              } catch (error) {
                setError(
                  "Failed to update 136 form: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() => {
              navigate(createRoute.incidentProgressPage(incidentWorkItemId));
            }}
            workflowVersion={workflowVersion}
          />
        )}
      </>
    );
  };

  return (
    <React.Suspense fallback={render(undefined)}>
      <Await resolve={incidentWorkItem} errorElement={<ErrorComponent />}>
        {(resolvedData: IncidentWorkItemWithContext) => render(resolvedData)}
      </Await>
    </React.Suspense>
  );
}
