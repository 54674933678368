import { Close } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";

export interface TransferRejectedModalProps {
  open: boolean;
  onClose: () => void;
  onTryAgainYes: () => void;
  onTryAgainNo: () => void;
  officerName?: string;
  officerShoulderNumber?: string;
}

export function TransferRejectedModal({
  open,
  onClose,
  onTryAgainYes,
  onTryAgainNo,
  officerName,
  officerShoulderNumber,
}: TransferRejectedModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PopupDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      disableDialogRootPadding={true}
    >
      <DialogTitle sx={{ fontSize: "1.250rem", fontWeight: "bold" }}>
        {t("transfer.rejectedTransfer.title")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          edge="end"
          sx={{
            position: "absolute",
            right: 15,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{ color: "primary", fontSize: "0.875rem" }}
          gutterBottom
        >
          <Typography
            sx={{ color: "primary", fontSize: "0.875rem" }}
            gutterBottom
          >
            {t("transfer.rejectedTransfer.declinedMessage", {
              officerName,
              officerShoulderNumber,
            })}
          </Typography>
          <Typography
            sx={{ color: "primary", fontSize: "0.875rem" }}
            gutterBottom
          >
            {t("transfer.rejectedTransfer.tryAgain")}
          </Typography>
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: 3,
        }}
      >
        <Button onClick={onTryAgainNo} color="error" variant="outlined">
          {t("common.no")}
        </Button>
        <Button onClick={onTryAgainYes} color="primary" variant="contained">
          {t("common.yes")}
        </Button>
      </DialogActions>
    </PopupDialog>
  );
}
