import { Close, HorizontalRule } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";
import { HeadingAndSubheading } from "../../components/IncidentWorkItems/IncidentWorkItemCard/IncidentWorkItemCard.js";
import { renderUkDateTime } from "../../components/util.js";

export interface TransferRequestedModalProps {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
  onReject: () => void;
  requestingOfficerName: string;
  receivingOfficerName: string;
  incidentId: string;
  patientName: string;
  createdAtDate: string;
}

function getDesktopContent(
  t: TFunction<"translation", undefined>,
  {
    receivingOfficerName,
    incidentId,
    patientName,
    createdAtDate,
  }: {
    receivingOfficerName: string;
    incidentId: string;
    patientName: string;
    createdAtDate: string;
  },
  onAccept: () => void,
  onReject: () => void,
) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "2rem",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <HeadingAndSubheading
        heading="transfer.requestedTransfer.createdAt"
        subheading={renderUkDateTime(dayjs(createdAtDate).toDate(), true)}
      />

      <HeadingAndSubheading
        heading="transfer.requestedTransfer.nameOfPerson"
        subheading={patientName}
      />

      <HeadingAndSubheading
        heading="transfer.requestedTransfer.incidentId"
        subheading={incidentId}
      />

      <HeadingAndSubheading
        heading="transfer.requestedTransfer.officerAssigned"
        subheading={receivingOfficerName}
      />

      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Button
          variant="outlined"
          color="error"
          onClick={onReject}
          sx={{ minWidth: "5rem" }}
        >
          {t("transfer.requestedTransfer.reject")}
        </Button>

        <Button
          variant="contained"
          onClick={onAccept}
          sx={{ minWidth: "5rem" }}
        >
          {t("transfer.requestedTransfer.accept")}
        </Button>
      </Box>
    </Box>
  );
}

function getMobileContent(
  t: TFunction<"translation", undefined>,
  theme: Theme,
  {
    receivingOfficerName,
    incidentId,
    patientName,
    createdAtDate,
  }: {
    receivingOfficerName: string;
    incidentId: string;
    patientName: string;
    createdAtDate: string;
  },
  onAccept: () => void,
  onReject: () => void,
) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border: "1px solid",
        borderRadius: "0.5rem",
        borderColor: theme.palette.grey[400],
        padding: "1rem",
        minHeight: "12rem",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ gap: "1rem", flexDirection: "column", display: "flex" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <HeadingAndSubheading
            heading="transfer.requestedTransfer.createdAt"
            subheading={renderUkDateTime(dayjs(createdAtDate).toDate(), true)}
          />
          <HeadingAndSubheading
            heading="transfer.requestedTransfer.nameOfPerson"
            subheading={patientName}
          />
        </Box>
        <HorizontalRule />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <HeadingAndSubheading
            heading="transfer.requestedTransfer.incidentId"
            subheading={incidentId}
          />

          <HeadingAndSubheading
            heading="transfer.requestedTransfer.officerAssigned"
            subheading={receivingOfficerName}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          gap: "1rem",
        }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={onReject}
          sx={{ minWidth: "5rem" }}
        >
          {t("transfer.requestedTransfer.reject")}
        </Button>

        <Button
          variant="contained"
          onClick={onAccept}
          sx={{ minWidth: "5rem" }}
        >
          {t("transfer.requestedTransfer.accept")}
        </Button>
      </Box>
    </Box>
  );
}
export function TransferRequestedModal({
  open,
  onClose,
  requestingOfficerName,
  receivingOfficerName,
  incidentId,
  patientName,
  createdAtDate,
  onAccept,
  onReject,
}: TransferRequestedModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <PopupDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      disableDialogRootPadding={true}
    >
      <DialogTitle sx={{ fontSize: "1.250rem", fontWeight: "bold" }}>
        {t("transfer.requestedTransfer.title")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          edge="end"
          sx={{
            position: "absolute",
            right: 15,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{ color: "primary", fontSize: "0.875rem" }}
          gutterBottom
        >
          {t("transfer.requestedTransfer.officerRequest", {
            requestingOfficerName,
          })}
        </Typography>

        {isDesktop
          ? getDesktopContent(
              t,
              {
                receivingOfficerName,
                incidentId,
                patientName,
                createdAtDate,
              },
              onAccept,
              onReject,
            )
          : getMobileContent(
              t,
              theme,
              {
                receivingOfficerName,
                incidentId,
                patientName,
                createdAtDate,
              },
              onAccept,
              onReject,
            )}
      </DialogContent>
    </PopupDialog>
  );
}
