import React, { useMemo } from "react";

import {
  FormSection,
  IncidentFormIdSchema,
  IncidentWorkItemWithContext,
  WorkflowVersions,
} from "@vision/common";
import {
  Await,
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import { v4 } from "uuid";
import api from "../../api/index.js";
import {
  PreEndIncidentForm,
  PreEndIncidentFormSkeleton,
} from "../../forms/EndIncident/PreEndIncidentForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { IncidentWorkItemLoaderDeferredData } from "../../routing/loaders/incidentWorkItem.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export type PreEndIncidentPageProps = Record<string, unknown>;

export function PreEndIncidentPage(_props: PreEndIncidentPageProps) {
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const { incidentWorkItem } = useRouteLoaderData(
    "incidentWorkItem",
  ) as IncidentWorkItemLoaderDeferredData;
  const [error, setError] = React.useState<string | undefined>(undefined);
  const formId = useMemo(() => IncidentFormIdSchema.parse(v4()), []);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }
    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem?.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      ?.workflowVersion as WorkflowVersions | undefined;

    return (
      <>
        {incidentWorkItemId === undefined || workflowVersion === undefined ? (
          <PreEndIncidentFormSkeleton />
        ) : (
          <PreEndIncidentForm
            onSubmit={async (values) => {
              try {
                if (incidentWorkItemId === undefined) {
                  return;
                }
                const createFormResponse =
                  await api.incidentWorkItem.createEndIncidentForm(
                    incidentWorkItemId,
                    formId,
                  );
                if (createFormResponse.status !== 204) {
                  throw Error(JSON.stringify(createFormResponse.data.message));
                }
                const response = await api.incidentWorkItem.updateData(
                  incidentWorkItemId,
                  formId,
                  FormSection.preEndIncident,
                  values,
                  true,
                );
                if (response.status === 204) {
                  revalidator.revalidate();
                  navigate(`/incident/${incidentWorkItemId}/section-complete`, {
                    state: {
                      type: FormSection.preEndIncident,
                      payload: values,
                    },
                  });
                  return;
                } else {
                  setError(JSON.stringify(response.data.message));
                }
              } catch (error) {
                setError(
                  "Failed to update 136 form: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() => {
              navigate(createRoute.incidentProgressPage(incidentWorkItemId));
            }}
            workflowVersion={workflowVersion}
          />
        )}
      </>
    );
  };

  return (
    <React.Suspense fallback={render(undefined)}>
      <Await resolve={incidentWorkItem} errorElement={<ErrorComponent />}>
        {(resolvedData: IncidentWorkItemWithContext) => render(resolvedData)}
      </Await>
    </React.Suspense>
  );
}
