import React, { useEffect, useState } from "react";

import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { TranslationKey } from "@vision/common";
import { useField } from "formik";
import { isEqual } from "lodash-es";
import { useTranslation } from "react-i18next";
import { FormError } from "../FormError/FormError.js";
import { FormLabel } from "../FormLabel/FormLabel.js";

type DropdownOption = {
  label: TranslationKey;
  value: string;
};

export type DropdownProps = {
  fieldName: string;
  label: TranslationKey | undefined;
  options: DropdownOption[];
};

export const Dropdown = ({ fieldName, label, options }: DropdownProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(fieldName);
  const [selectedValue, setSelectedValue] = useState<{
    label: string;
    value: string;
  } | null>(null);

  useEffect(() => {
    const findInitialValue = () => {
      for (const option of options) {
        const optionWithTranslatedLabel = {
          value: option.value,
          label: t(option.label),
        };
        if (isEqual(optionWithTranslatedLabel, field.value)) {
          return optionWithTranslatedLabel;
        }
      }
      return null;
    };
    const initialValue = findInitialValue();
    setSelectedValue(initialValue);
  }, [field.value, options, t]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    helpers.setValue(JSON.parse(event.target.value));
  };

  return (
    <>
      {label && <FormLabel label={label} />}
      <FormControl fullWidth variant="outlined">
        <Select
          labelId={`${fieldName}-label`}
          name={fieldName}
          value={JSON.stringify(selectedValue)}
          onChange={handleChange}
          data-testid={`formField-${fieldName}`}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={JSON.stringify({
                value: option.value,
                label: t(option.label),
              })}
              style={{ whiteSpace: "normal" }}
            >
              {t(option.label)}
            </MenuItem>
          ))}
        </Select>
        {meta.touched && meta.error && <FormError error={meta.error} />}
      </FormControl>
    </>
  );
};
