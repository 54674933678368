import { CircularProgress, Divider } from "@mui/material";
import {
  IncidentWorkItemWithContext,
  ParticipantRole,
  ParticipantStatus,
  UserIdUnbranded,
} from "@vision/common";
import React from "react";
import { Await, useRouteLoaderData } from "react-router-dom";
import {
  OfficerLandingPageLoaderData,
  OfficerLandingPageLoaderDeferredData,
} from "../../routing/loaders/officerLandingPage.js";
import { RootLoaderData } from "../../routing/loaders/root.js";
import { Error } from "../Error/Error.js";
import { IncidentList } from "./IncidentList.js";

/**
 * Determines whether a given user is actively involved in an incident.
 *
 * If a user has requested an ownership transfer, or if they are a participant
 * but other participants have been transferred while they are not the owner,
 * they are not considered part of the incident.
 *
 * @param {IncidentWorkItemWithContext} incident - The incident containing participants.
 * @param {UserIdUnbranded | undefined} userId - The ID of the user to check.
 * @returns {boolean} - Returns `true` if the user is actively involved in the incident, otherwise `false`.
 */
const isUserActiveInIncident = (
  { participants }: IncidentWorkItemWithContext,
  userId: UserIdUnbranded | undefined,
): boolean => {
  // If there's no user ID, they cannot own or be part of the incident
  if (!userId || !participants) return false;

  // Find the participant entry for the current user
  const currentUser = participants.find((p) => p.participantId === userId);
  if (!currentUser) return false; // If the user is not a participant, return false

  // Find the participant who is the owner of the incident
  const owner = participants.find((p) => p.role === ParticipantRole.owner);

  // Check if the current user is the owner but has requested a transfer
  const isOwnerRequestingTransfer =
    owner?.participantId === userId &&
    owner.status === ParticipantStatus.requestedTransfer;
  if (isOwnerRequestingTransfer) return false; // If so, they no longer "own" the incident

  // If the current user is a "pending owner", they should have potential access (accept or reject transfer)
  if (currentUser.role === ParticipantRole.pendingOwner) return true;

  // Check if any participants have been transferred away from the incident
  const hasTransferredParticipants = participants.some(
    (p) => p.status === ParticipantStatus.transferred,
  );

  // If there are transferred participants AND the current user is not the owner, return false
  const isNonOwnerWithTransferredParticipants =
    hasTransferredParticipants && owner?.participantId !== userId;

  // If the user is an owner or no transfers affect their status, return true
  return !isNonOwnerWithTransferredParticipants;
};

export const HomePageIncidents = () => {
  const { incidentWorkItems } = useRouteLoaderData(
    "home",
  ) as OfficerLandingPageLoaderDeferredData;

  const { user } = useRouteLoaderData("root") as RootLoaderData;

  const render = ({
    incidentWorkItems,
    user,
  }: {
    incidentWorkItems: IncidentWorkItemWithContext[] | undefined;
    user: RootLoaderData["user"] | undefined;
  }) => {
    if ([user, incidentWorkItems].some((x) => x === undefined)) {
      return <CircularProgress />;
    }

    const userId = user?.id;
    const myIncidents = (incidentWorkItems ?? []).filter((incident) =>
      isUserActiveInIncident(incident, userId),
    );

    const transferredIncidents = (incidentWorkItems ?? []).filter(
      (incident) => !isUserActiveInIncident(incident, userId),
    );

    return (
      <>
        <IncidentList
          incidentWorkItems={myIncidents}
          mode="myIncidents"
          isLoading={incidentWorkItems === undefined}
          userId={userId}
        />
        <Divider />
        <IncidentList
          incidentWorkItems={transferredIncidents}
          mode="transferredIncidents"
          isLoading={incidentWorkItems === undefined}
          userId={userId}
        />
      </>
    );
  };

  return (
    <React.Suspense
      fallback={render({ user: undefined, incidentWorkItems: undefined })}
    >
      <Await
        resolve={Promise.all([user, incidentWorkItems])}
        errorElement={<Error />}
      >
        {([user, incidentWorkItems]: [
          RootLoaderData["user"],
          OfficerLandingPageLoaderData["incidentWorkItems"],
        ]) => render({ user, incidentWorkItems })}
      </Await>
    </React.Suspense>
  );
};
