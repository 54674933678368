import { defined } from "@thalamos/common";
import { ok, safeTry } from "neverthrow";
import {
  FormSection,
  IncidentFormType,
  ParticipantStatus,
  type IncidentFormUnbranded,
} from "../types/index.js";
import type { ActionInput } from "./ActionInput.js";

export function incidentIsActive({ incidentWorkItem }: ActionInput): boolean {
  return (
    incidentWorkItem.incidentWorkItem.status === "active" ||
    incidentWorkItem.incidentWorkItem.status === "in-progress"
  );
}

export function hasTriageForm({ incidentWorkItem }: ActionInput): boolean {
  const triageForm = incidentWorkItem.forms.find(
    (form) => form.formType === IncidentFormType.triage,
  );
  if (!triageForm || !triageForm.sections) return false;

  const triageSection = triageForm.sections[0];
  if (!triageSection.data || Object.keys(triageSection.data).length === 0)
    return false;

  return true;
}

export function hasSection136Form({ incidentWorkItem }: ActionInput): boolean {
  const section136Form = incidentWorkItem.forms.find(
    (form) => form.formType === IncidentFormType.section136,
  );
  if (!section136Form || !section136Form.sections) return false;
  return true;
}

export function hasPlaceOfSafety({ incidentWorkItem }: ActionInput): boolean {
  const placeOfSafetyForm = incidentWorkItem.forms.find(
    (form) => form.formType === IncidentFormType.recordPlaceOfSafety,
  );
  if (!placeOfSafetyForm || !placeOfSafetyForm.sections) return false;
  return true;
}

export function userIsIncidentOwner({
  incidentWorkItem,
  user,
}: ActionInput): boolean {
  const ownerParticipant = incidentWorkItem.participants.find((participant) => {
    return participant.role === "owner";
  });

  return ownerParticipant?.participantId === user.id;
}

export function hasArrivedAtActivePlaceOfSafety({
  incidentWorkItem,
}: ActionInput): boolean {
  const result = safeTry(function* () {
    const activePlaceOfSafetyFormId = yield* defined(
      incidentWorkItem.incidentWorkItem.activePlaceOfSafetyFormId,
    );

    const activePlaceOfSafetyForm = yield* defined(
      incidentWorkItem.forms.find(
        (form) =>
          form.formType === IncidentFormType.recordPlaceOfSafety &&
          form.id === activePlaceOfSafetyFormId,
      ),
    );

    const activePlaceOfSafetyFormSectionArrival = yield* defined(
      activePlaceOfSafetyForm.sections.find((section) => {
        return section.id === FormSection.recordPlaceOfSafetyArrival;
      }),
    );

    const arrivalCompleted =
      activePlaceOfSafetyFormSectionArrival.data !== null;

    return ok(arrivalCompleted);
  });

  return result.unwrapOr(false);
}

function section136SectionComplete(
  section136Form: IncidentFormUnbranded,
  sectionId: string,
): boolean {
  const section = section136Form.sections.find(
    (section) => section.id === sectionId,
  );
  return section ? section?.data !== null : false;
}

export function section136FormCompleted(actionInput: ActionInput): boolean {
  if (hasSection136Form(actionInput) === false) return false;

  const section136Form = actionInput.incidentWorkItem.forms.find(
    (form) => form.formType === IncidentFormType.section136,
  );

  const sectionIds = [
    FormSection.personDetails,
    FormSection.detentionDetails,
    FormSection.informationForHealthcare,
  ];

  return sectionIds.every((sectionId) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    section136SectionComplete(section136Form!, sectionId),
  );
}

export function incidentHasTransferRequest({
  incidentWorkItem,
}: ActionInput): boolean {
  const pendingTransfer = incidentWorkItem.participants.some((participant) => {
    return participant.status === ParticipantStatus.pendingTransfer;
  });
  return pendingTransfer;
}
