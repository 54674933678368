import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  clinicalCaseRecordNumberSchema,
  FormSection,
  freeTextSchema,
  givenHealthCareProfessionalAdviceSchema,
  IncidentType,
  nhsContactSchema,
  otherActionSchema,
  reasonNotConsultedSchema,
  triageOutcomeSchema,
  valueAndLabelSchema,
  voluntaryActionSchema,
  yesNoSchema,
  yesOnlySchema,
  type FormDefinition,
  type FormSectionDefinition,
} from "../../types/index.js";

const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "isHealthcareProfessionalConsulted",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "triageIncident.isHealthcareProfessionalConsulted",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "howDidYouContactNhs",
    type: "dropdown",
    schema: valueAndLabelSchema(nhsContactSchema),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "yes",
    label: "triageIncident.howDidYouContactNhs.title",
    options: [
      {
        label:
          "triageIncident.howDidYouContactNhs.options.healthcarePractitionerAmbulance",
        value: "healthcarePractitionerAmbulance",
      },
      {
        label:
          "triageIncident.howDidYouContactNhs.options.ldPractitionersInCustody",
        value: "ldPractitionersInCustody",
      },
      {
        label:
          "triageIncident.howDidYouContactNhs.options.custodyHealthcarePractitioner",
        value: "custodyHealthcarePractitioner",
      },
      {
        label:
          "triageIncident.howDidYouContactNhs.options.panLondonMentalHealthAdviceLine",
        value: "panLondonMentalHealthAdviceLine",
      },
      {
        label:
          "triageIncident.howDidYouContactNhs.options.localMentalHealthAdviceLine",
        value: "localMentalHealthAdviceLine",
      },
      {
        label: "triageIncident.howDidYouContactNhs.options.localHbpos",
        value: "localHbpos",
      },
      {
        label:
          "triageIncident.howDidYouContactNhs.options.localStreetTriageTeam",
        value: "localStreetTriageTeam",
      },
      {
        label: "common.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "howDidYouContactNhsOtherDetails",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.howDidYouContactNhs?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withFieldConditionallyVisible({
    fieldName: "clinicalCaseRecordNumber",
    type: "textbox",
    ...new FieldBuilder().withSchema(clinicalCaseRecordNumberSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.howDidYouContactNhs?.value ===
      "panLondonMentalHealthAdviceLine",
    label: "triageIncident.clinicalCaseRecordNumber",
  })
  .withFieldConditionallyVisible({
    fieldName: "reasonNotConsulted",
    type: "dropdown",
    schema: valueAndLabelSchema(reasonNotConsultedSchema),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "no",
    label: "triageIncident.reasonNotConsulted.title",
    options: [
      {
        label: "triageIncident.reasonNotConsulted.options.needForCare",
        value: "needForCare",
      },
      {
        label: "triageIncident.reasonNotConsulted.options.immediateControl",
        value: "immediateControl",
      },
      {
        label: "triageIncident.reasonNotConsulted.options.noAnswer",
        value: "noAnswer",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "givenHealthCareProfessionalAdvice",
    type: "dropdown",
    schema: valueAndLabelSchema(givenHealthCareProfessionalAdviceSchema),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "yes",
    label: "triageIncident.givenHealthCareProfessionalAdvice",
    options: [
      {
        label: "triageIncident.whatWillYouDo.136",
        value: IncidentType.section136,
      },
      {
        label: "triageIncident.whatWillYouDo.56",
        value: IncidentType.section56,
      },
      {
        label: "triageIncident.whatWillYouDo.voluntary",
        value: IncidentType.voluntary,
      },
      {
        label: "common.other",
        value: IncidentType.other,
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "reasonsForDetention",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.section136,
    label: "triageIncident.reasonsForDetention",
  })
  .withFieldConditionallyVisible({
    fieldName: "confirmMentalCapacityLack",
    type: "checkbox",
    schema: valueAndLabelSchema(yesOnlySchema),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.section56,
    label: "triageIncident.confirmMentalCapacityLack",
  })
  .withFieldConditionallyVisible({
    fieldName: "voluntaryAction",
    type: "dropdown",
    schema: valueAndLabelSchema(voluntaryActionSchema),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.voluntary,
    label: "triageIncident.whatActionWillYouTake",
    options: [
      {
        label:
          "triageIncident.voluntaryAction.options.voluntaryAttendanceHospital",
        value: "voluntaryAttendanceHospital",
      },
      {
        label:
          "triageIncident.voluntaryAction.options.divertOtherHealthservice",
        value: "divertOtherHealthservice",
      },
      {
        label: "triageIncident.voluntaryAction.options.contactSupportGroup",
        value: "contactSupportGroup",
      },
      {
        label: "triageIncident.voluntaryAction.options.contactFriendsFamily",
        value: "contactFriendsFamily",
      },
      {
        label: "triageIncident.voluntaryAction.options.takePersonHome",
        value: "takePersonHome",
      },
      {
        label: "triageIncident.voluntaryAction.options.divertCrisisCafe",
        value: "divertCrisisCafe",
      },
      {
        label: "triageIncident.voluntaryAction.options.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "voluntaryActionOtherDetails",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.voluntaryAction?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withFieldConditionallyVisible({
    fieldName: "otherAction",
    type: "dropdown",
    schema: valueAndLabelSchema(otherActionSchema),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.other,
    label: "triageIncident.otherAction.title",
    options: [
      {
        label: "triageIncident.otherAction.options.arrestCriminalOffence",
        value: "arrestCriminalOffence",
      },
      {
        label: "triageIncident.otherAction.options.watchList",
        value: "watchList",
      },
      {
        label: "triageIncident.otherAction.options.referSupportOptions",
        value: "referSupportOptions",
      },
      {
        label: "triageIncident.otherAction.options.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "otherActionDetails",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.otherAction?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withFieldAlwaysVisible({
    fieldName: "whatWillYouDo",
    type: "dropdown",
    schema: valueAndLabelSchema(triageOutcomeSchema),
    label: "triageIncident.whatActionWillYouTake",
    options: [
      {
        label: "triageIncident.whatWillYouDo.136",
        value: "136",
      },
      {
        label: "triageIncident.whatWillYouDo.56",
        value: "5-6",
      },
      {
        label: "triageIncident.whatWillYouDo.voluntary",
        value: "voluntary",
      },
      {
        label: "triageIncident.whatWillYouDo.other",
        value: "other",
      },
    ],
  })
  .withPage(["isHealthcareProfessionalConsulted"])
  .withPage([
    "howDidYouContactNhs",
    "howDidYouContactNhsOtherDetails",
    "clinicalCaseRecordNumber",
    "reasonNotConsulted",
  ])
  .withPage([
    "givenHealthCareProfessionalAdvice",
    "reasonsForDetention",
    "confirmMentalCapacityLack",
    "voluntaryAction",
    "voluntaryActionOtherDetails",
    "otherAction",
    "otherActionDetails",
  ])
  .withPage(["whatWillYouDo"])
  .build();

export type TriageIncidentThalamosMax01FormData =
  typeof formSpecification.formType;

const triageIncidentThalamosMax01: FormDefinition<
  [
    FormSectionDefinition<
      FormSection.triageMain,
      TriageIncidentThalamosMax01FormData
    >,
  ]
> = {
  sections: [
    {
      id: FormSection.triageMain,
      autosave: true,
      strictFieldSchemas: formSpecification.strictFieldSchemas,
      formSchema: formSpecification.formSchema,
      name: "triageIncident.title",
      pages: formSpecification.pages,
    },
  ],
};

export default triageIncidentThalamosMax01;
