import { z } from "zod";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  dateTimeSchema,
  freeTextSchema,
  valueAndLabelSchema,
  yesNoSchema,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "endingReason",
    type: "dropdown",
    schema: valueAndLabelSchema(
      z.enum([
        "startedFormInError" as const,
        "formExpired" as const,
        "nhsNotEngage" as const,
        "medicalExamination" as const,
        "other" as const,
      ]),
    ),
    options: [
      {
        label: "preEndIncident.endingReason.options.startedFormInError",
        value: "startedFormInError",
      },
      {
        label: "preEndIncident.endingReason.options.formExpired",
        value: "formExpired",
      },
      {
        label: "preEndIncident.endingReason.options.nhsNotEngage",
        value: "nhsNotEngage",
      },
      {
        label: "preEndIncident.endingReason.options.medicalExamination",
        value: "medicalExamination",
      },
      {
        label: "preEndIncident.endingReason.options.other",
        value: "other",
      },
    ],
    label: "preEndIncident.endingReason.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "whyFormExpired",
    type: "multi-select",
    schema: z
      .array(
        valueAndLabelSchema(
          z.enum([
            "noBedAvailable" as const,
            "delayedAssessment" as const,
            "processProblems" as const,
          ]),
        ),
      )
      .min(1, "At least one option must be selected."),
    isVisible: ({ formValues }) =>
      formValues.endingReason?.value === "formExpired",
    label: "preEndIncident.whyFormExpired.title",
    options: [
      {
        label: "preEndIncident.whyFormExpired.options.noBedAvailable",
        value: "noBedAvailable",
      },
      {
        label: "preEndIncident.whyFormExpired.options.delayedAssessment",
        value: "delayedAssessment",
      },
      {
        label: "preEndIncident.whyFormExpired.options.processProblems",
        value: "processProblems",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "otherProcessProblemsReason",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      (formValues.whyFormExpired ?? []).some(
        (option) => option.value === "processProblems",
      ),
    label: "preEndIncident.whyFormExpired.otherProcessProblems",
  })
  .withFieldConditionallyVisible({
    fieldName: "whyNhsNotEngage",
    type: "dropdown",
    schema: valueAndLabelSchema(
      z.enum([
        "internetConnectivityIssue" as const,
        "emailNotReceived" as const,
        "handoverCompletedVerbally" as const,
        "usedLocalForm" as const,
        "other" as const,
      ]),
    ),
    isVisible: ({ formValues }) =>
      formValues.endingReason?.value === "nhsNotEngage",
    label: "preEndIncident.whyNhsNotEngage.title",
    options: [
      {
        label: "preEndIncident.whyNhsNotEngage.options.internetConnection",
        value: "internetConnectivityIssue",
      },
      {
        label: "preEndIncident.whyNhsNotEngage.options.emailNotReceived",
        value: "emailNotReceived",
      },
      {
        label:
          "preEndIncident.whyNhsNotEngage.options.handoverCompletedVerbally",
        value: "handoverCompletedVerbally",
      },
      {
        label: "preEndIncident.whyNhsNotEngage.options.localForm",
        value: "usedLocalForm",
      },
      {
        label: "preEndIncident.whyNhsNotEngage.options.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "additionalInformationNotEngage",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.whyNhsNotEngage?.value === "other",
    label: "preEndIncident.whyNhsNotEngage.additionalInformationNotEngage",
  })
  .withFieldConditionallyVisible({
    fieldName: "wasAssessedMentalHealthPlaceOfSafety",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    isVisible: ({ formValues }) =>
      formValues.whyNhsNotEngage?.value === "usedLocalForm" ||
      formValues.whyNhsNotEngage?.value === "other",
    label: "preEndIncident.wasAssessedMentalHealthPlaceOfSafety",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "incidentStart",
    type: "date-time",
    schema: dateTimeSchema,
    dateLabel: "preEndIncident.incidentStartDate",
    timeLabel: "preEndIncident.incidentStartTime",
    isVisible: ({ formValues }) =>
      formValues.wasAssessedMentalHealthPlaceOfSafety?.value === "yes",
  })
  .withFieldConditionallyVisible({
    fieldName: "arriveFirstPos",
    type: "date-time",
    schema: dateTimeSchema,
    dateLabel: "preEndIncident.incidentDateArrivePos",
    timeLabel: "preEndIncident.incidentTimeArrivePos",
    isVisible: ({ formValues }) =>
      formValues.wasAssessedMentalHealthPlaceOfSafety?.value === "yes",
  })
  .withFieldConditionallyVisible({
    fieldName: "handoverOccur",
    type: "date-time",
    schema: dateTimeSchema,
    dateLabel: "preEndIncident.incidentDateHandover",
    timeLabel: "preEndIncident.incidentTimeHandover",
    isVisible: ({ formValues }) =>
      formValues.wasAssessedMentalHealthPlaceOfSafety?.value === "yes",
  })
  .withFieldConditionallyVisible({
    fieldName: "leavePos",
    type: "date-time",
    schema: dateTimeSchema,
    dateLabel: "preEndIncident.incidentLeaveDatePos",
    timeLabel: "preEndIncident.incidentLeaveTimePos",
    isVisible: ({ formValues }) =>
      formValues.wasAssessedMentalHealthPlaceOfSafety?.value === "yes",
  })
  .withFieldConditionallyVisible({
    fieldName: "outcomeOfAssessment",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "preEndIncident.wasAssessedMentalHealthPlaceOfSafety",
    isVisible: ({ formValues }) =>
      formValues.wasAssessedMentalHealthPlaceOfSafety?.value === "yes",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "selectedAssessmentOutcome",
    type: "dropdown",
    schema: valueAndLabelSchema(
      z.enum(["discharged" as const, "admitted" as const, "other" as const]),
    ),
    isVisible: ({ formValues }) =>
      formValues.outcomeOfAssessment?.value === "yes",
    label: "preEndIncident.selectedAssessmentOutcome.title",
    options: [
      {
        label: "preEndIncident.selectedAssessmentOutcome.options.discharged",
        value: "discharged",
      },
      {
        label: "preEndIncident.selectedAssessmentOutcome.options.admitted",
        value: "admitted",
      },
      {
        label: "preEndIncident.selectedAssessmentOutcome.options.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "furtherCareType",
    type: "dropdown",
    schema: valueAndLabelSchema(
      z.enum([
        "furtherDetention" as const,
        "voluntary" as const,
        "unknown" as const,
      ]),
    ),
    isVisible: ({ formValues }) =>
      formValues.selectedAssessmentOutcome?.value === "admitted",
    label: "preEndIncident.furtherCareType.title",
    options: [
      {
        label: "preEndIncident.furtherCareType.options.furtherDetention",
        value: "furtherDetention",
      },
      {
        label: "preEndIncident.furtherCareType.options.voluntary",
        value: "voluntary",
      },
      {
        label: "preEndIncident.furtherCareType.options.unknown",
        value: "unknown",
      },
    ],
  })

  .withPage(["endingReason"])
  .withPage(["whyFormExpired", "otherProcessProblemsReason"])
  .withPage(["whyNhsNotEngage", "additionalInformationNotEngage"])
  .withPage(["wasAssessedMentalHealthPlaceOfSafety"])
  .withPage(["incidentStart"])
  .withPage(["arriveFirstPos"])
  .withPage(["handoverOccur"])
  .withPage(["leavePos"])
  .withPage(["outcomeOfAssessment"])
  .withPage(["selectedAssessmentOutcome", "furtherCareType"])
  .build();

export type PreEndIncidentFormData = typeof formSpecification.formType;
