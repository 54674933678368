import { ThalamosNotification } from "@vision/common";
import { AxiosInstance } from "axios";

export const config = {
  notification: {
    unread: () => ({
      url: `/api/notifications/unread`,
      method: "get",
    }),
    markAsRead: (notificationId: string) => ({
      url: `/api/notifications/viewed`,
      method: "post",
      data: { notificationId },
    }),
  },
};

export const api = (apiAxios: AxiosInstance) => {
  return {
    notification: {
      unread: () =>
        apiAxios.request<ThalamosNotification[]>(config.notification.unread()),
      markAsRead: (notificationId: string) =>
        apiAxios.request(config.notification.markAsRead(notificationId)),
    },
  };
};
