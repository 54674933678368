import { z, ZodType } from "zod";
import type { FormSection } from "./Workflow.js";

type FormSectionCompletedEndIncidentUnbranded = {
  endingReason: { label: string };
};

type FormSectionCompletePosUnbranded = {
  pos: string;
  arrivalDate: string;
};

const _FormSectionCompletePosSchema = z.object({
  pos: z.string(),
  arrivalDate: z.string(),
}) satisfies ZodType<FormSectionCompletePosUnbranded>;

const _FormSectionCompletedEndIncidentSchema = z.object({
  endingReason: z.object({ label: z.string() }),
}) satisfies ZodType<FormSectionCompletedEndIncidentUnbranded>;

export type FormSectionCompletePos = z.infer<
  typeof _FormSectionCompletePosSchema
>;

export type FormSectionCompletedEndIncident = z.infer<
  typeof _FormSectionCompletedEndIncidentSchema
>;

type FormSectionCompleteIncidentMainUnbranded = {
  officer: string;
};

const _FormSectionCompleteIncidentMainSchema = z.object({
  officer: z.string(),
}) satisfies ZodType<FormSectionCompleteIncidentMainUnbranded>;

export type FormSectionCompleteIncidentMain = z.infer<
  typeof _FormSectionCompleteIncidentMainSchema
>;

export type FormSectionCompleteProps = {
  type: FormSection;
  payload?:
    | FormSectionCompletePos
    | FormSectionCompleteIncidentMain
    | FormSectionCompletedEndIncident;
};
