import { UserSchema } from "../../types/index.js";
import { parentOrg, rootOrg } from "./organisations.js";

export const joeBloggs = UserSchema.parse({
  id: "cf5bf028-8cd7-412b-aac6-8bae367b186f",
  name: "Joe Bloggs",
  email: "joe@bloggs.com",
  shoulderNumber: "123456",
});

export const janeDoe = UserSchema.parse({
  id: "19354453-879c-46a7-bcf6-ecb81660d19a",
  name: "Jane Doe",
  email: "jane@doe.com",
  shoulderNumber: "789012",
});

export const users = [joeBloggs, janeDoe];

export const joeBloggsOrganisationMembership = {
  organisationId: rootOrg.id,
  userId: joeBloggs.id,
  role: "officer",
  isOnboarded: true,
};

export const janeDoeOrganisationMembership = {
  organisationId: parentOrg.id,
  userId: janeDoe.id,
  role: "officer",
  isOnboarded: true,
};

export const userOrganisationMemberships = [
  joeBloggsOrganisationMembership,
  janeDoeOrganisationMembership,
];
