import axios from "axios";

import { config } from "../config/index.js";
import { api as helloApi } from "./hello/api.js";
import { api as incidentWorkItemApi } from "./incidentWorkItem/api.js";
import { api as notificationApi } from "./notification/api.js";
import { api as organisationApi } from "./organisation/api.js";
import { api as placeOfSafetyApi } from "./placeOfSafety/api.js";
import { api as userApi } from "./user/api.js";

const apiAxios = axios.create({
  // Important for cookies etc.
  withCredentials: true,
  baseURL: config.backendUrl,
  validateStatus: (status) => status >= 200 && status < 500,
});

apiAxios.interceptors.response.use((response) => {
  if (response.status === 401) {
    window.location.href = `${window.location.origin}/login`;
  }

  return response;
});

export default {
  ...helloApi(apiAxios),
  ...incidentWorkItemApi(apiAxios),
  ...organisationApi(apiAxios),
  ...userApi(apiAxios),
  ...placeOfSafetyApi(apiAxios),
  ...notificationApi(apiAxios),
};
