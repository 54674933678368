import { z, ZodBoolean, ZodString, type ZodTypeAny } from "zod";
import { nullableSchema } from "../../types/formFieldSchemas.js";

/**
 * A utility class to help build a schema for a field.
 * The output is designed to be spread onto an underlying FormField.
 * 
 * Example usage:
 * ```
  const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "myField",
    type: "radio",
    label: "detentionDetails.custodyNumber",
    options: [
      { label: "personDetails.ethnicity.options.IC1", value: "option1" },
      { label: "personDetails.ethnicity.options.IC2", value: "option2" },
      { label: "personDetails.ethnicity.options.IC3", value: "option3" },
    ],
    ...new FieldBuilder()
      .withNullableSchema(z.enum(["option1", "option2", "option3"]))
      .captureValueLabel()
      .build(),
  })
  .withPage(["myField"])
  .build();
```
 * 
 */
export class FieldBuilder<
  InputFieldSchema extends ZodTypeAny | undefined = undefined,
  CaptureValueLabel extends boolean = false,
  CanBeNullable extends boolean = false,
  CanBeUnknown extends boolean = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  RawOutputType extends z.ZodRawShape = {},
> {
  private m_inputFieldSchema: InputFieldSchema | undefined;
  private m_captureValueLabel: true | undefined;
  private m_canBeNullable: true | undefined;
  private m_canBeUnknown: true | undefined;

  constructor() {
    this.m_inputFieldSchema = undefined;
    this.m_captureValueLabel = undefined;
    this.m_canBeNullable = undefined;
    this.m_canBeUnknown = undefined;
  }

  /**
   * Specify the Zod schema for the underlying form value.
   * Can only be called once, and must be called before withLabel.
   *
   * @param schema The Zod schema for the underlying form value.
   * @returns
   */
  withSchema<Input extends ZodTypeAny>(
    schema: Input,
  ): InputFieldSchema extends ZodTypeAny
    ? never
    : FieldBuilder<
        Input,
        CaptureValueLabel,
        false,
        false,
        z.objectUtil.extendShape<RawOutputType, { value: Input }>
      > {
    this.m_inputFieldSchema = schema as unknown as InputFieldSchema;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this as any;
  }

  /**
   * Specify the Zod schema for the underlying form value, enhanced to be nullable.
   * Can only be called once, and must be called before withLabel.
   *
   * @param schema The Zod schema for the underlying form value.
   * @returns
   */
  withNullableSchema<Input extends ZodTypeAny>(
    schema: Input,
  ): InputFieldSchema extends ZodTypeAny
    ? never
    : FieldBuilder<
        ReturnType<typeof nullableSchema<Input>>,
        CaptureValueLabel,
        true,
        false,
        z.objectUtil.extendShape<RawOutputType, { value: Input }>
      > {
    this.m_inputFieldSchema = schema as unknown as InputFieldSchema;
    this.m_canBeNullable = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this as any;
  }

  /**
   * Specify the Zod schema for the underlying form value, enhanced to be allow it to be unknown.
   * Can only be called once, and must be called before withLabel.
   *
   * @param schema The Zod schema for the underlying form value.
   * @returns
   */
  withUnknowableSchema<Input extends ZodTypeAny>(
    schema: Input,
  ): InputFieldSchema extends ZodTypeAny
    ? never
    : FieldBuilder<
        ReturnType<typeof nullableSchema<Input>>,
        CaptureValueLabel,
        false,
        true,
        z.objectUtil.extendShape<
          RawOutputType,
          {
            value: ReturnType<typeof nullableSchema<Input>>;
            isUnknown: ZodBoolean;
          }
        >
      > {
    this.m_inputFieldSchema = schema as unknown as InputFieldSchema;
    this.m_canBeUnknown = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this as any;
  }

  /**
   * Indicates that we want to capture the label presented to the user for the field.
   *
   * @returns
   */
  captureValueLabel(): InputFieldSchema extends ZodTypeAny
    ? FieldBuilder<
        InputFieldSchema,
        true,
        CanBeNullable,
        CanBeUnknown,
        z.objectUtil.extendShape<RawOutputType, { label: ZodString }>
      >
    : never {
    this.m_captureValueLabel = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this as any;
  }

  /**
   * Builds the field schema.
   *
   * @returns An object to be spread onto an underlying FormField.
   */
  build(): InputFieldSchema extends ZodTypeAny
    ? CanBeNullable extends true
      ? {
          schema: ReturnType<
            typeof nullableSchema<ReturnType<typeof z.object<RawOutputType>>>
          >;
        }
      : CanBeUnknown extends true
        ? {
            schema: ReturnType<typeof z.object<RawOutputType>>;
            canBeUnknown: true;
          }
        : {
            schema: ReturnType<typeof z.object<RawOutputType>>;
          }
    : never {
    // It shouldn't be possible to build a field without a schema, but just in case...
    if (!this.m_inputFieldSchema) {
      throw new Error("Field schema is not defined");
    }

    // Generate the raw object we will use to make a Zod Schema
    const rawOutputSchema = {
      value: this.m_canBeUnknown
        ? nullableSchema(this.m_inputFieldSchema)
        : this.m_inputFieldSchema,
      ...(this.m_captureValueLabel ? { valueLabel: z.string() } : {}),
      ...(this.m_canBeUnknown ? { isUnknown: z.boolean() } : {}),
    } as unknown as RawOutputType;

    // Generate the Zod schema
    const baseSchema = z.object<RawOutputType>(rawOutputSchema);
    const outputSchema = this.m_canBeNullable
      ? nullableSchema(baseSchema)
      : this.m_canBeUnknown
        ? baseSchema.refine(
            (data) => data.isUnknown === true || data.value !== null,
          )
        : baseSchema;

    // Package everything up ready to be returned
    const outputResult = {
      schema: outputSchema,
      canBeUnknown: this.m_canBeUnknown,
    } as unknown as InputFieldSchema extends ZodTypeAny
      ? CanBeNullable extends true
        ? {
            schema: ReturnType<
              typeof nullableSchema<ReturnType<typeof z.object<RawOutputType>>>
            >;
          }
        : CanBeUnknown extends true
          ? {
              schema: ReturnType<typeof z.object<RawOutputType>>;
              canBeUnknown: true;
            }
          : {
              schema: ReturnType<typeof z.object<RawOutputType>>;
            }
      : never;

    return outputResult;
  }
}
