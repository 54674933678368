import React, { useCallback, useMemo } from "react";

import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { TranslationKey } from "@vision/common";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FormError } from "../FormError/FormError.js";
import { FormLabel } from "../FormLabel/FormLabel.js";

type MultiSelectOption = {
  label: TranslationKey;
  value: string;
};

export type MultiSelectProps = {
  fieldName: string;
  label: TranslationKey;
  options: MultiSelectOption[];
};

type SelectedField = { value: string; label: string };

export const MultiSelect = ({
  fieldName,
  label,
  options,
}: MultiSelectProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField<SelectedField[] | "">(fieldName);

  const fieldValue: SelectedField[] = useMemo(
    () => (field.value === "" ? [] : field.value),
    [field.value],
  );

  const toggleValue = useCallback(
    ({ label, value }: { label: string; value: string }) => {
      // Is this value already selected?
      const alreadySelected = fieldValue
        .map(({ value }) => value)
        .includes(value);

      const newFieldValue = alreadySelected
        ? // Remove it from the array
          fieldValue.filter((entry) => entry.value !== value)
        : // Add it to the array, but with the translated label
          [...fieldValue, { value, label: t(label) }];

      helpers.setValue(newFieldValue);
    },
    [fieldValue, helpers, t],
  );

  return (
    <Box data-testid={`formField-${fieldName}`}>
      <FormLabel data-testid="checkbox-group-label" label={label} />

      <Stack>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={JSON.stringify({
              value: option.value,
              label: t(option.label),
            })}
            control={
              <Checkbox
                checked={fieldValue
                  .map((entry) => entry.value)
                  .includes(option.value)}
                onChange={() => toggleValue(option)}
              />
            }
            label={t(option.label)}
          />
        ))}
      </Stack>

      {meta.touched && meta.error && <FormError error={meta.error} />}
    </Box>
  );
};
