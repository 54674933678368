import type { UUID } from "@thalamos/common";

export type ThalamosNotification = {
  id: UUID;
  text: BrowserNotificationEvent;
  status: "new" | "deleted";
  userId: string;
};

export enum NotificationEvents {
  transferRequested = "transfer-requested",
  transferRejected = "transfer-rejected",
}

export type TransferRequestedEvent = {
  type: NotificationEvents.transferRequested;
  requestingOfficerName: string;
  receivingOfficerName: string;
  incidentId: string;
  patientSurname: string;
  patientForename: string;
  createdAtDate: string;
};

export type TransferRejectedEvent = {
  type: NotificationEvents.transferRejected;
  officerName: string;
  officerShoulderNumber?: string;
  incidentId: string;
};

export type BrowserNotificationEvent =
  | TransferRequestedEvent
  | TransferRejectedEvent;

export const notificationChannelFns = {
  transfer: {
    requested: (userId: string) => `transfer.requested.${userId}`,
    rejected: (userId: string) => `transfer.rejected.${userId}`,
  },
};
