import React from "react";

import { Router } from "@remix-run/router";
import { createBrowserRouter } from "react-router-dom";
import { Logo } from "../components/Logo/Logo.js";
import { RootComponent } from "../components/RootComponent/RootComponent.js";
import { ArrivePlaceOfSafetyPage } from "../pages/ArrivePlaceOfSafetyPage/ArrivePlaceOfSafetyPage.js";
import { CreateIncidentWorkItemPage } from "../pages/CreateIncidentWorkItemPage/CreateIncidentWorkItemPage.js";
import { DetentionDetailsPage } from "../pages/DetentionDetailsPage/DetentionDetailsPage.js";
import { PreEndIncidentPage } from "../pages/EndIncidentPage/PreEndIncidentPage.js";
import { Error as ErrorPage } from "../pages/Error/Error.js";
import { FormProgressWrapper } from "../pages/FormProgress/FormProgress.js";
import { FormSectionCompletePage } from "../pages/FormSectionCompletePage/FormSectionCompletePage.js";
import HomePage from "../pages/Home/Home.js";
import { InformationForHealthcarePage } from "../pages/InformationForHealthcare/InformationForHealthcare.js";
import { LeavePlaceOfSafetyPage } from "../pages/LeavePlaceOfSafetyPage/LeavePlaceOfSafetyPage.js";
import { Login } from "../pages/Login/Login.js";
import { PersonDetailsPage } from "../pages/PersonDetailsPage/PersonDetailsPage.js";
import { Placeholder } from "../pages/Placeholder/Placeholder.js";
import { PlacesOfSafetyPage } from "../pages/PlacesOfSafetyPage/PlacesOfSafetyPage.js";
import { RecordPlaceOfSafetyPage } from "../pages/RecordPlaceOfSafetyPage/RecordPlaceOfSafetyPage.js";
import { RecordPolicePresencePage } from "../pages/RecordPolicePresencePage/RecordPolicePresencePage.js";
import { TriageIncidentPage } from "../pages/TriageIncidentPage/TriageIncidentPage.js";
import { UserProfilePage } from "../pages/UserProfile/UserProfile.js";
import { incidentWorkItemLoader } from "./loaders/incidentWorkItem.js";
import { officerLandingPageLoader } from "./loaders/officerLandingPage.js";
import { placesOfSafetyLoader } from "./loaders/placesOfSafety.js";
import { rootLoader } from "./loaders/root.js";

/**
 * Create a React Router representing the application's routes
 *
 * @returns Router to be passed into a RouterProvider
 */
export const createRouter: () => Router = () => {
  return createBrowserRouter([
    {
      id: "root",
      path: "/",
      element: <RootComponent />,
      errorElement: <ErrorPage />,
      loader: rootLoader,
      children: [
        {
          errorElement: <ErrorPage />,
          children: [
            {
              id: "home",
              index: true,
              loader: officerLandingPageLoader,
              element: <HomePage />,
            },
            {
              id: "placesOfSafety",
              path: "places-of-safety",
              loader: placesOfSafetyLoader,
              element: <PlacesOfSafetyPage />,
            },
            { path: "help", element: <Placeholder title={"Help"} /> },
            {
              path: "incident/active",
              element: <HomePage />,
            },
            {
              id: "incidentWorkItem",
              path: "incident/:id",
              loader: incidentWorkItemLoader,
              children: [
                {
                  path: "progress",
                  element: <FormProgressWrapper />,
                },
                {
                  path: "triage",
                  element: <TriageIncidentPage errorHandling={"inline"} />,
                },
                {
                  path: "section-complete",
                  element: <FormSectionCompletePage />,
                },
                {
                  path: "person-details",
                  element: <PersonDetailsPage errorHandling={"inline"} />,
                },
                {
                  path: "detention-details",
                  element: <DetentionDetailsPage errorHandling={"inline"} />,
                },
                {
                  path: "information-for-healthcare",
                  element: (
                    <InformationForHealthcarePage errorHandling={"inline"} />
                  ),
                },
                {
                  path: "place-of-safety/create",
                  element: <RecordPlaceOfSafetyPage />,
                },
                {
                  path: "place-of-safety/:formId/arrival",
                  element: <ArrivePlaceOfSafetyPage />,
                },
                {
                  path: "place-of-safety/:formId/leave",
                  element: <LeavePlaceOfSafetyPage />,
                },
                {
                  path: "pre-end-incident",
                  element: <PreEndIncidentPage />,
                },
                {
                  path: "record-police-presence",
                  element: <RecordPolicePresencePage />,
                },
              ],
            },
            {
              path: "incident/archive",
              element: <Placeholder title={"Ended Incidents"} />,
            },
            {
              path: "incident/create",
              element: <CreateIncidentWorkItemPage errorHandling={"throw"} />,
            },
            {
              path: "notifications",
              element: <Placeholder title={"Notifications"} />,
            },
            {
              path: "profile/:id",
              element: <UserProfilePage />,
            },
          ],
        },
      ],
    },
    {
      path: "login",
      // TODO organisation logo
      element: <Login logo={<Logo />} />,
    },
  ]);
};
