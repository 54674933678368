import { MultiPageFormBuilder } from "../../support/MultiPageFormBuilder/index.js";
import {
  FormSection,
  triageOutcomeSchema,
  valueAndLabelSchema,
  yesNoSchema,
  type FormDefinition,
  type FormSectionDefinition,
} from "../../types/index.js";

const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "isHealthcareProfessionalConsulted",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "triageIncident.isHealthcareProfessionalConsulted",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldAlwaysVisible({
    fieldName: "whatWillYouDo",
    type: "dropdown",
    schema: valueAndLabelSchema(triageOutcomeSchema),
    label: "triageIncident.whatActionWillYouTake",
    options: [
      {
        label: "triageIncident.whatWillYouDo.136",
        value: "136",
      },
      {
        label: "triageIncident.whatWillYouDo.56",
        value: "5-6",
      },
      {
        label: "triageIncident.whatWillYouDo.voluntary",
        value: "voluntary",
      },
      {
        label: "triageIncident.whatWillYouDo.other",
        value: "other",
      },
    ],
  })
  .withPage(["isHealthcareProfessionalConsulted"])
  .withPage(["whatWillYouDo"])
  .build();

export type TriageIncidentThalamosMin01FormData =
  typeof formSpecification.formType;

const triageIncidentThalamosMin01: FormDefinition<
  [
    FormSectionDefinition<
      FormSection.triageMain,
      TriageIncidentThalamosMin01FormData
    >,
  ]
> = {
  sections: [
    {
      id: FormSection.triageMain,
      autosave: true,
      strictFieldSchemas: formSpecification.strictFieldSchemas,
      formSchema: formSpecification.formSchema,
      name: "triageIncident.title",
      pages: formSpecification.pages,
    },
  ],
};

export default triageIncidentThalamosMin01;
