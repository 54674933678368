import {
  ExtendedVisionUser,
  notificationChannelFns,
  NotificationEvents,
  ThalamosNotification,
  TransferRejectedEvent,
  TransferRequestedEvent,
} from "@vision/common";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useRevalidator } from "react-router-dom";
import api from "../../api/index.js";
import { useInitiateTransferModal } from "../../hooks/useInitiateTransferModal.js";
import { useToastNotifications } from "../../hooks/useToastNotifications.js";
import { useChannel } from "../../lib/pusher/useChannel.js";
import { useEvent } from "../../lib/pusher/useEvent.js";
import { TransferRejectedModal } from "../../modals/TransferRejected/TransferRejected.js";
import { TransferRequestedModal } from "../../modals/TransferRequested/TransferRequested.js";
import { createRoute } from "../../routing/createRoute.js";

export const Notifications = ({
  user,
  children,
}: {
  user: ExtendedVisionUser;
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const notifications = useToastNotifications();
  const revalidator = useRevalidator();

  const [isTransferRequestedModalOpen, setIsTransferRequestedModalOpen] =
    useState(false);
  const [transferRequestedData, setTransferRequestedData] =
    useState<ThalamosNotification | null>(null);

  const [isTransferRejectedModalOpen, setIsTransferRejectedModalOpen] =
    useState(false);
  const [transferRejectedData, setTransferRejectedData] =
    useState<ThalamosNotification | null>(null);

  const { openInitiateTransferModal, InitiateTransferModalComponent } =
    useInitiateTransferModal();

  useEffect(() => {
    // Fetch unread notifications on login
    const fetchUnreadNotifications = async () => {
      const response = await api.notification.unread();

      if (response.status === 200) {
        response.data.forEach((notification: ThalamosNotification) => {
          if (notification.text.type === "transfer-requested") {
            setTransferRequestedData(notification);
            setIsTransferRequestedModalOpen(true);
          } else if (notification.text.type === "transfer-rejected") {
            setTransferRejectedData(notification);
            setIsTransferRejectedModalOpen(true);
          }
        });
      } else {
        notifications.show(t("notifications.failedRetrieve"), {
          severity: "error",
        });
      }
    };

    fetchUnreadNotifications();
  }, [user.id]);

  const transferRequestedChannel = useChannel(
    notificationChannelFns.transfer.requested(user.id),
  );

  useEvent(
    transferRequestedChannel,
    NotificationEvents.transferRequested,
    (event) => {
      setTransferRequestedData(event as ThalamosNotification);
      setIsTransferRequestedModalOpen(true);
    },
  );

  const transferRejectedChannel = useChannel(
    notificationChannelFns.transfer.rejected(user.id),
  );

  useEvent(
    transferRejectedChannel,
    NotificationEvents.transferRejected,
    (event) => {
      setTransferRejectedData(event as ThalamosNotification);
      setIsTransferRejectedModalOpen(true);
    },
  );

  const handleCloseRequestedModal = () => {
    setIsTransferRequestedModalOpen(false);
  };

  const handleAcceptTransfer = () => {
    setIsTransferRequestedModalOpen(false);

    api.incidentWorkItem
      .resolveTransfer({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        incidentWorkItemId: transferRequestedData!.text.incidentId,
        isAccepted: true,
      })
      .then((res) => {
        if (res.status === 204) {
          api.notification
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .markAsRead(transferRequestedData!.id)
            .then((res) => {
              if (res.status !== 204) {
                notifications.show(t("notifications.failedMarkAsRead"), {
                  severity: "error",
                });
              }
            });

          notifications.show(t("incidentWorkItem.notificationAcceptedShow"), {
            severity: "success",
          });
          navigate(
            createRoute.incidentProgressPage(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              transferRequestedData!.text.incidentId,
            ),
          );
        } else {
          notifications.show(t("incidentWorkItem.notificationAcceptedError"), {
            severity: "error",
          });
        }
      });
  };

  const handleRejectTransfer = () => {
    setIsTransferRequestedModalOpen(false);
    api.incidentWorkItem
      .resolveTransfer({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        incidentWorkItemId: transferRequestedData!.text.incidentId,
        isAccepted: false,
      })
      .then((res) => {
        if (res.status === 204) {
          api.notification
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .markAsRead(transferRequestedData!.id)
            .then((res) => {
              if (res.status !== 204) {
                notifications.show(t("notifications.failedMarkAsRead"), {
                  severity: "error",
                });
              }
            });
          notifications.show(t("incidentWorkItem.notificationRejectedShow"), {
            severity: "success",
          });
          revalidator.revalidate();
        } else {
          notifications.show(t("incidentWorkItem.notificationRejectedError"), {
            severity: "error",
          });
        }
      });
  };

  const handleCloseRejectedModal = () => {
    setIsTransferRejectedModalOpen(false);
  };

  const handleOnTryAgainNo = async () => {
    setIsTransferRejectedModalOpen(false);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await api.notification.markAsRead(transferRejectedData!.id);
    if (res.status !== 204) {
      notifications.show(t("notifications.failedMarkAsRead"), {
        severity: "error",
      });
    }
  };

  const handleOnTryAgainYes = () => {
    setIsTransferRejectedModalOpen(false);
    if (transferRejectedData) {
      openInitiateTransferModal(
        transferRejectedData.text.incidentId,
        transferRejectedData.id,
      );
    }
  };

  return (
    <>
      {children}
      {transferRequestedData && (
        <TransferRequestedModal
          open={isTransferRequestedModalOpen}
          onClose={handleCloseRequestedModal}
          onAccept={handleAcceptTransfer}
          onReject={handleRejectTransfer}
          requestingOfficerName={
            (transferRequestedData.text as TransferRequestedEvent)
              .requestingOfficerName
          }
          receivingOfficerName={
            (transferRequestedData.text as TransferRequestedEvent)
              .receivingOfficerName
          }
          incidentId={
            (transferRequestedData.text as TransferRequestedEvent).incidentId
          }
          patientName={`${(transferRequestedData.text as TransferRequestedEvent).patientForename} ${(transferRequestedData.text as TransferRequestedEvent).patientSurname}`}
          createdAtDate={
            (transferRequestedData.text as TransferRequestedEvent).createdAtDate
          }
        />
      )}

      {transferRejectedData && (
        <TransferRejectedModal
          open={isTransferRejectedModalOpen}
          onClose={handleCloseRejectedModal}
          onTryAgainNo={handleOnTryAgainNo}
          onTryAgainYes={handleOnTryAgainYes}
          officerName={
            (transferRejectedData.text as TransferRejectedEvent).officerName
          }
          officerShoulderNumber={
            (transferRejectedData.text as TransferRejectedEvent)
              .officerShoulderNumber
          }
        />
      )}

      {InitiateTransferModalComponent}
    </>
  );
};
