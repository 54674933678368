import React from "react";

import { FormSectionCompleteProps } from "@vision/common";
import { useLocation } from "react-router-dom";
import { FormSectionComplete } from "../../components/FormSectionComplete/FormSectionComplete.js";

export const FormSectionCompletePage = () => {
  const location = useLocation();
  const { type, payload } = location.state as FormSectionCompleteProps;

  return <FormSectionComplete type={type} payload={payload} />;
};
