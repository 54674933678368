import { Close } from "@mui/icons-material/";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IncidentWorkItemIdUnbranded } from "@vision/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";

export interface InitiateTransferModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (email: string) => void;
  incidentWorkItemId: IncidentWorkItemIdUnbranded | undefined;
}

export function InitiateTransferModal({
  open,
  onClose,
  onConfirm,
  incidentWorkItemId,
}: InitiateTransferModalProps) {
  const [email, setEmail] = React.useState<string>("");
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleConfirm = () => {
    // TODO - think about how we want to handle validation
    if (email) {
      onConfirm(email.trim());
    }
  };

  return (
    <PopupDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      disableDialogRootPadding={true}
    >
      <DialogTitle sx={{ fontSize: "1.250rem", fontWeight: "bold" }}>
        {t("transfer.initiateTransfer.title")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          edge="end"
          sx={{
            position: "absolute",
            right: 15,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{ color: "primary", fontSize: "0.875rem" }}
          gutterBottom
        >
          <Typography
            sx={{ color: "primary", fontSize: "0.875rem" }}
            gutterBottom
          >
            {t("transfer.initiateTransfer.transferIncidentWorkItem", {
              incidentWorkItemId,
            })}
          </Typography>
          <Typography
            sx={{ color: "primary", fontSize: "0.875rem" }}
            gutterBottom
          >
            {t("transfer.initiateTransfer.transferIncidentWorkItemId", {
              incidentWorkItemId,
            })}
          </Typography>
          {t("transfer.initiateTransfer.confirmedTransfer")}
        </Typography>
        <Typography
          sx={{ color: "primary", fontSize: "0.875rem", mb: 2 }}
          gutterBottom
        >
          {t("transfer.initiateTransfer.untilTransferAccepted")}
        </Typography>
        <TextField
          label={t("transfer.initiateTransfer.enterEmailAddress")}
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: 3,
        }}
      >
        <Button onClick={onClose} color="error" variant="outlined">
          {t("common.close")}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={!email}
        >
          {t("transfer.initiateTransfer.confirm")}
        </Button>
      </DialogActions>
    </PopupDialog>
  );
}
