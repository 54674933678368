import { match } from "ts-pattern";
import { z } from "zod";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  freeTextSchema,
  placeOfSafetySchema,
  valueAndLabelSchema,
  yesOnlySchema,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "placeOfSafety",
    type: "placeOfSafety",
    schema: placeOfSafetySchema,
    label: "recordPlaceOfSafety.choosePlaceOfSafety.title",
  })
  .withFieldAlwaysVisible({
    fieldName: "confirmPlaceOfSafetyCorrect",
    type: "checkbox",
    schema: valueAndLabelSchema(yesOnlySchema),
    label: "recordPlaceOfSafety.choosePlaceOfSafety.confirmCorrect",
  })
  .withFieldAlwaysVisible({
    fieldName: "placeOfSafetyType",
    type: "dropdown",
    schema: valueAndLabelSchema(
      z.enum([
        "hbPos136" as const,
        "emergencyDepartment" as const,
        "returnedToMentalHealthUnit" as const,
        "careHome" as const,
        "custody" as const,
        "privateHome" as const,
        "other" as const,
      ]),
    ),
    options: [
      {
        label: "recordPlaceOfSafety.choosePlaceOfSafetyType.options.hbPos136",
        value: "hbPos136",
      },
      {
        label:
          "recordPlaceOfSafety.choosePlaceOfSafetyType.options.emergencyDepartment",
        value: "emergencyDepartment",
      },
      {
        label:
          "recordPlaceOfSafety.choosePlaceOfSafetyType.options.returnedToMentalHealthUnit",
        value: "returnedToMentalHealthUnit",
      },
      {
        label: "recordPlaceOfSafety.choosePlaceOfSafetyType.options.careHome",
        value: "careHome",
      },
      {
        label: "recordPlaceOfSafety.choosePlaceOfSafetyType.options.custody",
        value: "custody",
      },
      {
        label:
          "recordPlaceOfSafety.choosePlaceOfSafetyType.options.privateHome",
        value: "privateHome",
      },
      {
        label: "recordPlaceOfSafety.choosePlaceOfSafetyType.options.other",
        value: "other",
      },
    ],
    label: "recordPlaceOfSafety.choosePlaceOfSafetyType.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "placeOfSafetyTypeOther",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.placeOfSafetyType?.value === "other",
    label: "recordPlaceOfSafety.choosePlaceOfSafetyTypeOther.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "whyNotHbPosReason",
    type: "dropdown",
    schema: valueAndLabelSchema(
      z.enum([
        "noHbPosAvailable" as const,
        "unreasonableDelay" as const,
        "medicalAttentionNeeded" as const,
        "secureCustodyRequired" as const,
        "other" as const,
      ]),
    ),
    options: [
      {
        label: "recordPlaceOfSafety.whyNotHbPosReason.options.noHbPosAvailable",
        value: "noHbPosAvailable",
      },
      {
        label:
          "recordPlaceOfSafety.whyNotHbPosReason.options.unreasonableDelay",
        value: "unreasonableDelay",
      },
      {
        label:
          "recordPlaceOfSafety.whyNotHbPosReason.options.medicalAttentionNeeded",
        value: "medicalAttentionNeeded",
      },
      {
        label:
          "recordPlaceOfSafety.whyNotHbPosReason.options.secureCustodyRequired",
        value: "secureCustodyRequired",
      },
      {
        label: "recordPlaceOfSafety.whyNotHbPosReason.options.other",
        value: "other",
      },
    ],
    isVisible: ({ formValues }) =>
      match(formValues.placeOfSafetyType?.value)
        .with("hbPos136", () => false)
        .otherwise(() => true),
    // TODO: I would rather write this from below, but I'm getting through "" from Formik. Could something outside of here co-erce "" to undefined?
    // .with(undefined, () => false)
    // .with(
    //   "other",
    //   "emergencyDepartment",
    //   "returnedToMentalHealthUnit",
    //   "careHome",
    //   "custody",
    //   "privateHome",
    //   () => true,
    // )
    // .exhaustive(),
    label: "recordPlaceOfSafety.whyNotHbPosReason.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "whyNotHbPosReasonOther",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      match(formValues.whyNotHbPosReason?.value)
        .with("other", () => true)
        .otherwise(() => false),
    label: "recordPlaceOfSafety.whyNotHbPosReasonOther.title",
  })
  .withPage(["placeOfSafety", "confirmPlaceOfSafetyCorrect"])
  .withPage(["placeOfSafetyType", "placeOfSafetyTypeOther"])
  .withPage(["whyNotHbPosReason", "whyNotHbPosReasonOther"])
  .build();

export type RecordPlaceOfSafetyFormData = typeof formSpecification.formType;
