import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  dateSchema,
  ethnicitySchema,
  forenameSchema,
  freeTextSchema,
  genderSchema,
  selfDeclaredEthnicitySchema,
  surnameSchema,
  valueAndLabelSchema,
  yesNoSchema,
  yesNoUnknownSchema,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "patientAddress",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    label: "personDetails.homeAddress",
  })
  .withFieldAlwaysVisible({
    fieldName: "dateOfBirth",
    type: "date",
    label: "personDetails.dob",
    ...new FieldBuilder().withSchema(dateSchema).build(),
  })
  .withFieldAlwaysVisible({
    fieldName: "gender",
    type: "dropdown",
    schema: valueAndLabelSchema(genderSchema),
    label: "personDetails.gender.title",
    options: [
      {
        label: "personDetails.gender.options.male",
        value: "male",
      },
      {
        label: "personDetails.gender.options.female",
        value: "female",
      },
      {
        label: "personDetails.gender.options.nonBinary",
        value: "nonBinary",
      },
      {
        label: "personDetails.gender.options.preferNotToSay",
        value: "preferNotToSay",
      },
    ],
  })
  .withFieldAlwaysVisible({
    fieldName: "ethnicAppearance",
    type: "dropdown",
    schema: valueAndLabelSchema(ethnicitySchema),
    label: "personDetails.ethnicity.title",
    options: [
      {
        label: "personDetails.ethnicity.options.IC1",
        value: "IC1",
      },
      {
        label: "personDetails.ethnicity.options.IC3",
        value: "IC3",
      },
      {
        label: "personDetails.ethnicity.options.IC4",
        value: "IC4",
      },
      {
        label: "personDetails.ethnicity.options.IC5",
        value: "IC5",
      },
      {
        label: "personDetails.ethnicity.options.IC6",
        value: "IC6",
      },
      {
        label: "personDetails.ethnicity.options.IC7",
        value: "IC7",
      },
    ],
  })
  .withFieldAlwaysVisible({
    fieldName: "selfDeclaredEthnicity",
    type: "dropdown",
    schema: valueAndLabelSchema(selfDeclaredEthnicitySchema),
    label: "personDetails.selfDeclaredEthnicity.title",
    options: [
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteEnglish",
        value: "whiteEnglish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteIrish",
        value: "whiteIrish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteGypsy",
        value: "whiteGypsy",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteRoma",
        value: "whiteRoma",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteOther",
        value: "whiteOther",
      },
      {
        label:
          "personDetails.selfDeclaredEthnicity.options.mixedBlackCaribbean",
        value: "mixedBlackCaribbean",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.mixedBlackAfrican",
        value: "mixedBlackAfrican",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.mixedAsian",
        value: "mixedAsian",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.mixedOther",
        value: "mixedOther",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianEnglish",
        value: "asianEnglish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianIndian",
        value: "asianIndian",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianPakistani",
        value: "asianPakistani",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianBangladeshi",
        value: "asianBangladeshi",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianChinese",
        value: "asianChinese",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianOther",
        value: "asianOther",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackEnglish",
        value: "blackEnglish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackAfrican",
        value: "blackAfrican",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackCaribbean",
        value: "blackCaribbean",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackOther",
        value: "blackOther",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.arab",
        value: "arab",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.other",
        value: "other",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.preferNotToSay",
        value: "preferNotToSay",
      },
    ],
  })
  .withFieldAlwaysVisible({
    fieldName: "hasRelativeOrFriend",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "personDetails.relativeOrFriend",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendForename",
    type: "textbox",
    ...new FieldBuilder().withSchema(forenameSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendForename",
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendSurname",
    type: "textbox",
    ...new FieldBuilder().withSchema(surnameSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendSurname",
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendRelationship",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendRelationship",
  })
  .withReadonlyFieldConditionallyVisible({
    fieldName: "unknownRelativeOrFriend",
    type: "info-banner",
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "no",
    label: "personDetails.unknownRelativeOrFriend",
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendSameAddress",
    type: "radio",
    schema: valueAndLabelSchema(yesNoUnknownSchema),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendSameAddress",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
      {
        label: "unknown",
        value: "unknown",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendAddress",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.relativeOrFriendSameAddress?.value === "yes" ||
      formValues.relativeOrFriendSameAddress?.value === "no",
    label: "personDetails.relativeOrFriendAddress",
  })
  .withReadonlyFieldConditionallyVisible({
    fieldName: "unknownRelativeOrFriendAddress",
    type: "info-banner",
    isVisible: ({ formValues }) =>
      formValues.relativeOrFriendSameAddress?.value === "unknown",
    label: "personDetails.unknownRelativeOrFriendAddress",
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendTelephoneNumber",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendTelephone",
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendInformed",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendInformed",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "reasonNotInformed",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.relativeOrFriendInformed?.value === "no",
    label: "personDetails.reasonNotInformed",
  })
  .withPage(["patientAddress"])
  .withPage(["dateOfBirth"])
  .withPage(["gender"])
  .withPage(["ethnicAppearance", "selfDeclaredEthnicity"])
  .withPage([
    "hasRelativeOrFriend",
    "relativeOrFriendForename",
    "relativeOrFriendSurname",
    "relativeOrFriendRelationship",
    "unknownRelativeOrFriend",
  ])
  .withPage([
    "relativeOrFriendSameAddress",
    "relativeOrFriendAddress",
    "unknownRelativeOrFriendAddress",
  ])
  .withPage([
    "relativeOrFriendTelephoneNumber",
    "relativeOrFriendInformed",
    "reasonNotInformed",
  ])
  .build();

export type PersonDetailsFormData = typeof formSpecification.formType;
